import { Alert, Snackbar } from "@mui/material";
import React from "react";

export default function DisplayError({ error, open, handleClose }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      sx={{ width: "100%" }}
    >
      <Alert
        severity="error"
        onClose={handleClose}
        sx={{ textAlign: "center" }}
      >
        {error}
      </Alert>
    </Snackbar>
  );
}
