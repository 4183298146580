import { Box, Grid } from "@mui/material";
import React from "react";

export const WhiteBox = ({ children }) => {
  return (
    <Grid container>
      <Grid md={12} xs={12}>
        <Box
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            minHeight: "70vh",
            borderRadius: 2,
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
