import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { styles } from "../../../common/css/styles";
import { TransactionDetails } from "./TransactionDetails";
import { DeadState } from "../../../common/components/DeadState";

const dummyData = [
  {
    transactionID: "#123456789",
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "08012345678",
    narration: "Refund",
    // amount: "₦2,500,000",
    outgoingAmount: "₦950,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#341234567",
    firstName: "Tope",
    lastName: "Alabi",
    phoneNumber: "08012345678",
    narration: "interest payment",
    amount: "₦2,500,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#189623456",
    firstName: "Paul",
    lastName: "Wilbur",
    phoneNumber: "08012345678",
    narration: "interest payment",
    amount: "₦2,500,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#123456789",
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "08012345678",
    narration: "Refund",
    // amount: "₦2,500,000",
    outgoingAmount: "₦750,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#123456789",
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "08012345678",
    narration: "Refund",
    // amount: "₦2,500,000",
    outgoingAmount: "₦950,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#341234567",
    firstName: "Tope",
    lastName: "Alabi",
    phoneNumber: "08012345678",
    narration: "interest payment",
    amount: "₦2,500,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#189623456",
    firstName: "Paul",
    lastName: "Wilbur",
    phoneNumber: "08012345678",
    narration: "interest payment",
    amount: "₦2,500,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#123456789",
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "08012345678",
    narration: "Refund",
    // amount: "₦2,500,000",
    outgoingAmount: "₦750,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#123456789",
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "08012345678",
    narration: "Refund",
    // amount: "₦2,500,000",
    outgoingAmount: "₦950,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#341234567",
    firstName: "Tope",
    lastName: "Alabi",
    phoneNumber: "08012345678",
    narration: "interest payment",
    amount: "₦2,500,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#189623456",
    firstName: "Paul",
    lastName: "Wilbur",
    phoneNumber: "08012345678",
    narration: "interest payment",
    amount: "₦2,500,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
  {
    transactionID: "#123456789",
    firstName: "John",
    lastName: "Doe",
    phoneNumber: "08012345678",
    narration: "Refund",
    // amount: "₦2,500,000",
    outgoingAmount: "₦750,000",
    date: "2023-10-03",
    time: "14:30:00",
  },
];

const columns = [
  { id: "transactionID", label: "Transaction ID" },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "status", label: "Status" },
  { id: "amount", label: "Amount" },
  { id: "timeStamp", label: "Timestamp" },
  { id: "action", label: "Action" },
];

export const CollectionTable = () => {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSort = (columnId) => {
    if (orderBy === columnId) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(columnId);
      setOrder("asc");
    }
  };

  const sortedData = dummyData.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  return (
    <Paper
      sx={{
        // maxHeight: 400,
        boxShadow: "none",
        pt: 2,
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-head": {
                color: "#000",
                backgroundColor: "#fff",
                borderTop: 0.5,
                borderBottom: 0.5,
                borderColor: "#B5B5B5",
                py: 2,
              },
            }}
          >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  // color: "#62635E",
                }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableCell-body": {
              color: "#000",
              backgroundColor: "#fff",
              borderBottom: 0.5,
              borderColor: "#F7F8F4",
            },
          }}
        >
          {/* {sortedData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Typography noWrap sx={styles.table.text}>
                  {row.transactionID}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography noWrap sx={styles.table.text}>
                  {row.firstName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography noWrap sx={styles.table.text}>
                  {row.lastName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography noWrap sx={styles.table.text}>
                  {row.phoneNumber}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography noWrap sx={styles.table.text}>
                  {row.narration}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  sx={{
                    ...styles.table.text,
                    color: row.amount ? "green" : "red",
                  }}
                >
                  {row.amount || row.outgoingAmount}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography noWrap sx={styles.table.text}>
                  {row.date}
                </Typography>
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={handleOpen}
                  sx={styles.table.text.viewButton}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))} */}

          <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
            <DeadState />
          </TableCell>
        </TableBody>
      </Table>
      <TransactionDetails open={open} handleClose={handleClose} />
    </Paper>
  );
};
