import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { feedback } from "../../../../common/constants/feedback";
import { approveLoan, clearLoanFeedback } from "../store/businessLoanSlice";
import { ApproveSuccess } from "./ApproveSuccess";
import { ApproveFailed } from "./ApproveFailed";

export const ApproveLoan = ({ open, handleClose }) => {
  const businessLoan = useSelector((state) => state.businessLoan);
  const loan = businessLoan?.loan;
  const dispatch = useDispatch();

  const [openApproveSuccess, setOpenApproveSuccess] = useState(false);
  const [openApproveFailed, setOpenApproveFailed] = useState(false);

  const handleCloseApproveSuccess = () => {
    dispatch(clearLoanFeedback());
    setOpenApproveSuccess(false);
  };

  const handleCloseApproveFailed = () => {
    dispatch(clearLoanFeedback());
    setOpenApproveFailed(false);
  };

  const handleApproval = () => {
    handleClose();
    dispatch(approveLoan(loan.loanUid));
  };

  useEffect(() => {
    if (businessLoan.approved === feedback.SUCCESSFUL)
      setOpenApproveSuccess(true);
    if (businessLoan.approved === feedback.FAILED) setOpenApproveFailed(true);
  }, [businessLoan.approved]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "500px",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "280px", display: "flex", justifyContent: "center" }}
            ></Box>
            <DialogContentText>
              <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                Approve Loan
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
              >
                Are you sure you want to approve this loan for disbursement?
              </Typography>
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  ...styles.bridgeCardTop.error,
                  fontSize: 11,
                  px: 1,
                  mx: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ ...styles.bridgeCardTop.success, fontSize: 11, px: 1 }}
                onClick={handleApproval}
              >
                Accept
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

      <ApproveSuccess
        open={openApproveSuccess}
        handleClose={handleCloseApproveSuccess}
      />
      <ApproveFailed
        open={openApproveFailed}
        handleClose={handleCloseApproveFailed}
      />
    </>
  );
};
