import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { styles } from "../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredCustomers,
  searchCustomers,
} from "../customers/store/customersSlice";
import { customerFilters } from "../customers/constants/customerFilters";
import { CollectionTable } from "./components/CollectionsTable";
import { FilterSection } from "../../common/components/FilterSection";

export const Collection = () => {
  const customers = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const [activeFilter, setActiveFilter] = useState(customerFilters.ALL);

  const handleSearch = (searchTerm) => {
    dispatch(searchCustomers(1, searchTerm));
  };

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const filters = [
    { label: "All", value: customerFilters.ALL, visible: true },
    { label: "Prospects", value: customerFilters.PROSPECT, visible: true },
    {
      label: "Pending Verification",
      value: customerFilters.PENDING,
      visible: true,
    },
    { label: "Verified", value: customerFilters.ACTIVE, visible: true },
    { label: "Denied", value: customerFilters.DENIED, visible: true },
  ];

  useEffect(() => {
    dispatch(filteredCustomers(activeFilter));
  }, [activeFilter]);

  return (
    <>
      <SectionHeader headerText="Collection" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FilterSection />
          </Stack>
          <CollectionTable />
        </Box>
      </WhiteBox>
    </>
  );
};
