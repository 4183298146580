import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { Box, Button, Stack, Typography } from "@mui/material";
import { UserManagementTable } from "./UserManagementTable";
import { SearchBar } from "../../common/components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { fetchUser, fetchUsers, searchUsers } from "./usersSlice";
import { AddUser } from "./components/AddUser";
import { EditUser } from "./components/EditUser";

export const UserManagement = () => {
  const [showList, setShowList] = useState(true);
  const [option, setOption] = useState("");
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSearch = (searchTerm) => {
    dispatch(searchUsers({ page: 1, searchTerm }));
  };

  const handleAdd = () => {
    setShowList(false);
    setOption("Add");
  };

  const handleComplete = () => {
    setShowList(true);
    setOption("");
  };

  const handleEdit = (userUid) => {
    dispatch(fetchUser(userUid));
    setShowList(false);
    setOption("Edit");
  };

  useEffect(() => {
    dispatch(fetchUsers({ page: 1, filterOption: "" }));
  }, []);

  return (
    <>
      <SectionHeader headerText="User Management" />
      <WhiteBox>
        {showList ? (
          <Box p={2}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <SearchBar onSearch={handleSearch} />
              <Button
                variant="outlined"
                onClick={handleAdd}
                sx={{
                  height: 30,
                  textTransform: "capitalize",
                  color: "#000",
                  borderColor: "#000",
                  mt: 2.5,
                }}
              >
                <Add sx={{ fontSize: 12 }} />
                <Typography sx={{ fontSize: 12, pl: 1, pr: 0.5 }}>
                  Add Admin
                </Typography>
              </Button>
            </Stack>
            <UserManagementTable users={users} handleEdit={handleEdit} />
          </Box>
        ) : option === "Add" ? (
          <AddUser handleComplete={handleComplete} />
        ) : (
          <EditUser handleComplete={handleComplete} />
        )}
      </WhiteBox>
    </>
  );
};
