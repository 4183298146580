import React from "react";
import logo from "./logo.svg";
import { ThemeProvider } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { theme } from "./common/themesConfig";

// Customer Routes
import { Customers } from "./features/customers/Customers";

// Bridge Finance Routes
import { BridgeFinance } from "./features/bridgeFinance/BridgeFinance";
import { Overview } from "./features/overview/Overview";
import { PublicLayout } from "./common/layout/PublicLayout";
import { Login } from "./features/auth/Login";
import { PrivateLayout } from "./common/layout/PrivateLayout";
import { BridgeFinanceKYC } from "./features/bridgeFinance/BridgeFinanceKYC";
// import { AddCustomer } from "./features/addCustomer/AddCustomer";
import { CustomerKYC } from "./features/customers/CustomerKYC";
import { ForgotPassword } from "./features/auth/ForgotPassword";
import { UserManagement } from "./features/userManagement/UserManagement";
import { VerifyOTP } from "./features/auth/VerifyOTP";
import { WalletTransactions } from "./features/wallet/WalletTransactions";
import { Loans } from "./features/loans/Loans";
import { AuditLogs } from "./features/audit/AuditLogs";
import { Settings } from "./features/settings/Settings";
import { NotFound } from "./common/components/NotFound";
import { Disbursements } from "./features/disbursements/Disbursements";
import { Collection } from "./features/wallet/Collection";
import { AddCustomer } from "./features/customers/addCustomer/AddCustomer";
import { ResetPassword } from "./features/auth/ResetPassword";
import { ChangeDevice } from "./features/changeDevice/ChangeDevice";
import { LoanOverview } from "./features/loans/LoanOverview";
import { LoanDetails } from "./features/loans/LoanDetails";
import { Registered } from "./features/business/Registered";
import { BusinessInformation } from "./features/business/BusinessInformation";
import { UnRegistered } from "./features/business/UnRegistered";
import { BusinessLoans } from "./features/smeLoans/businessLoan/BusinessLoans";
import { BusinessLoanOverview } from "./features/smeLoans/businessLoan/BusinessLoanOverview";
import { BusinessLoanDetails } from "./features/smeLoans/businessLoan/BusinessLoanDetails";
import { TeamMemberDetails } from "./features/smeLoans/businessLoan/teamMemberDetails";
import { AssetFinancing } from "./features/smeLoans/assetFinancing/AssetFinancing";
import { AssetFinancingLoanOverview } from "./features/smeLoans/assetFinancing/AssetFinancingLoanOverview";
import { loanType } from "./features/smeLoans/constants/loanType";
import { UpdatedLoans } from "./features/updatedLoans/UpdatedLoans";
import { UpdatedLoanOverview } from "./features/updatedLoans/UpdatedLoanOverview";
import { UpdatedLoanDetails } from "./features/updatedLoans/UpdatedLoanDetails";
import NewRetailLoanOverview from "./features/updatedLoans/components/NewRetailLoanOverview";
import NewBusinessLoanOverview from "./features/updatedLoans/components/NewBusinessLoanOverview";
import NewAssetFinancingLoanOverview from "./features/updatedLoans/components/NewAssetFinancingLoanOverview";
import NewBridgeFinancingLoanOverview from "./features/updatedLoans/components/NewBridgeFinancingLoanOverview";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/verify-otp" element={<VerifyOTP />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="/set-password" element={<ResetPassword />} />
          </Route>
          <Route element={<PrivateLayout />}>
            {/* Overview */}
            <Route path="/overview" element={<Overview />} />

            {/* Customers */}
            <Route path="/customers/accounts" element={<Customers />} />
            <Route
              path="/customers/accounts/kyc/:uid"
              element={<CustomerKYC />}
            />
            <Route path="/customers/add-new" element={<AddCustomer />} />

            {/* Business */}
            <Route path="/business/registered" element={<Registered />} />
            <Route
              path="/business/registered/information/:uid"
              element={<BusinessInformation />}
            />
            <Route path="/business/unregistered" element={<UnRegistered />} />
            <Route
              path="/business/unregistered/information/:uid"
              element={<BusinessInformation />}
            />

            {/* Bridge Routes */}
            <Route path="/bridge-finance" element={<BridgeFinance />} />
            {/* <Route
              path="/bridge-finance/kyc/:loanid"
              element={<BridgeFinanceKYC />}
            /> */}
            <Route
              path="/bridge-finance/kyc/:loanid"
              element={<NewBridgeFinancingLoanOverview />}
            />

            {/* Loans */}
            <Route path="/loans" element={<Loans />} />
            <Route path="/loans/overview/:loanid" element={<LoanOverview />} />
            {/* <Route
              path="/loans/overview/:loanid"
              element={<NewRetailLoanOverview />}
            /> */}
            <Route path="/loans/details/:loanid" element={<LoanDetails />} />

            {/*Updated Loans Design*/}
            <Route path="/new-loans" element={<UpdatedLoans />} />
            <Route
              path="/new-loans/overview/:loanid"
              element={<UpdatedLoanOverview />}
            />
            <Route
              path="/new-loans/details/:loanid"
              element={<UpdatedLoanDetails />}
            />

            {/* SMELoans */}
            <Route
              path="/sme/loans"
              element={<BusinessLoans type={loanType.BUSINESS} />}
            />
            <Route
              path="/sme/loans/overview/:loanid"
              element={<BusinessLoanOverview type={loanType.BUSINESS} />}
            />
            {/* <Route
              path="/sme/loans/overview/:loanid"
              element={<NewBusinessLoanOverview />}
            /> */}
            <Route
              path="/sme/loans/details/:loanid"
              element={<BusinessLoanDetails />}
            />
            <Route
              path="/sme/loans/overview/loan-details/:loanid"
              element={<BusinessLoanDetails />}
            />

            {/* Asset Financing */}
            <Route
              path="/sme/asset-financing"
              element={<BusinessLoans type={loanType.ASSET_FINANCING} />}
            />
            <Route
              path="/sme/asset-financing/overview/:loanid"
              element={<BusinessLoanOverview type={loanType.ASSET_FINANCING} />}
            />
            {/* <Route
              path="/sme/asset-financing/overview/:loanid"
              element={<NewAssetFinancingLoanOverview />}
            /> */}
            <Route
              path="/sme/asset-financing/details/:loanid"
              element={<BusinessLoanDetails />}
            />

            {/* Wallet Transfer */}
            <Route
              path="/transactions/wallets"
              element={<WalletTransactions />}
            />
            <Route
              path="/transactions/disbursements"
              element={<Disbursements />}
            />
            <Route path="/transactions/collections" element={<Collection />} />

            {/* Audit Log */}
            <Route path="/audit-logs" element={<AuditLogs />} />

            {/* Settings */}
            <Route path="/Settings" element={<Settings />} />

            <Route path="/user-management" element={<UserManagement />} />

            <Route path="/customers/change-device" element={<ChangeDevice />} />
          </Route>
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/pl" element={<PrivateLayout />} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
