import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getLoans = ({ page, loanType, filterOption }) => {
  return client.get(
    `/sme/loans?page=${page}&size=${size}&loanType=${loanType}&loanStatus=${filterOption}`
  );
};

export const getLoan = (loanUid) => {
  return client.get(`sme/loans/${loanUid}`);
};

export const getBusinessTeamMembersByLoanId = (loanUid) => {
  return client.get(`sme/loans/business-team/${loanUid}`);
};

export const getCollateral = (loanUid) => {
  return client.get(`sme/loans/collateral/${loanUid}`);
};

export const getGuarantors = (loanUid) => {
  return client.get(`sme/loans/guarantors/${loanUid}`);
};

export const getDocuments = (loanUid) => {
  return client.get(`sme/loans/business-document/${loanUid}`);
};

export const approveForDisbursement = (loanUid) => {
  return client.patch("sme/loans/recommend-disbural", { loanUid });
};

export const getLoanHistory = ({ loanUid, page }) => {
  return client.get(
    `sme/loans/history?uid=${loanUid}&page=${page}&size=${size}`
  );
};

export const searchLoans = (page, search) => {
  return client.get(
    `sme/loans/search?search=${search}&page=${page}&size=${size}`
  );
};

export const setLoanRecommender = (loanUid) => {
  return client.patch(`loans/${loanUid}/set-recommender`);
};

export const recommendLoanOffer = ({
  loanUid,
  recommendOffer,
  recommendedAmount,
  recommendedInterestRate,
  recommendedTenure,
  reasonsForRecommendation,
}) => {
  return client.patch(`sme/loans/recommend-offer`, {
    loanUid,
    recommendOffer,
    recommendedAmount,
    recommendedInterestRate,
    recommendedTenure,
    reasonsForRecommendation,
  });
};

export const setLoanOfferer = (loanUid) => {
  return client.patch(`loans/${loanUid}/set-offerer`);
};

export const offerLoan = ({
  loanUid,
  isLoanOffered,
  loanOfferAmount,
  loanOfferInterestRate,
  loanOfferTenure,
  reasonsForLoanRequestDecision,
}) => {
  return client.patch("sme/loans/make-offer", {
    loanUid,
    isLoanOffered,
    loanOfferAmount,
    loanOfferInterestRate,
    loanOfferTenure,
    reasonsForLoanRequestDecision,
  });
};

export const internationalPassport = ({ image, loanUid }) => {
  return client.patch(
    "loans/international-passport",
    { image, loanUid },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const lienDocuments = ({
  loanUid,
  adminOverseeingLoan,
  adminPlacingLien,
  files,
  lienBankAccountName,
  lienBankAccountNumber,
  lienBankName,
  lienBankSortCode,
}) => {
  return client.post(
    "loans/upload-lien-documents",
    {
      loanUid,
      adminOverseeingLoan,
      adminPlacingLien,
      files,
      lienBankAccountName,
      lienBankAccountNumber,
      lienBankName,
      lienBankSortCode,
    },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const disburse = ({ loanUid, accountNumber, amount }) => {
  return client.post(`disburse`, { loanUid, accountNumber, amount });
};

export const uploadProofOfRepayment = ({ loanUid, proofOfRepayment }) => {
  return client.post(
    "loans/proof-of-repayment",
    { loanUid, proofOfRepayment },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};
