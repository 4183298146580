import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCustomerStats,
  getLoanMetrics,
  getLoanStats,
  getLoanStatsMetrics,
  getPendingCustomers,
  getPendingLoans,
  getStatistics,
  getLoanStatsInsights,
} from "../../common/api/overview";
import { handleError } from "../../common/constants/utility";

const initialState = {
  statistics: {},
  loanMetrics: {},
  pendingCustomers: [],
  pendingLoans: [],
  loansPie: {},
  loanInsights: { loans: [], totalRecords: 0 },
  customersPie: {},
  loanStats: {},
  customerStats: {},
  loadingStatistics: false,
  loadingLoanMetrics: false,
  loadingPendingCustomers: false,
  loadingPendingLoans: false,
  loadingCustomerStats: false,
  loadingLoanStats: false,
  loadingLoanInsights: false,
  error: [],
};

export const fetchStatistics = createAsyncThunk(
  "overview/statistics",
  async ({ startDate, endDate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getStatistics(startDate, endDate);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchLoanMetrics = createAsyncThunk(
  "overview/loanMetrics/fetch",
  async (
    { startDate, endDate, type, status },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getLoanStatsMetrics(
        startDate,
        endDate,
        type,
        status
      );
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchPendingCustomers = createAsyncThunk(
  "overview/pendingCustomers/fetch",
  async ({ startDate, endDate, page }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getPendingCustomers(startDate, endDate, page);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchPendingLoans = createAsyncThunk(
  "overview/pendingLoans/fetch",
  async (
    { startDate, endDate, page, filterOption },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await getPendingLoans(
        startDate,
        endDate,
        page,
        filterOption
      );
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchLoansPie = createAsyncThunk(
  "overview/pie/fetchLoans",
  async ({ startDate, endDate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getStatistics(startDate, endDate);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchCustomersPie = createAsyncThunk(
  "overview/pie/fetchCustomers",
  async ({ startDate, endDate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getStatistics(startDate, endDate);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);
export const fetchCustomerStats = createAsyncThunk(
  "overview/fetchCustomerStats",
  async ({ startDate, endDate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCustomerStats(startDate, endDate);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);
export const fetchLoanStats = createAsyncThunk(
  "overview/fetchLoanStats",
  async ({ startDate, endDate, type }, { dispatch, rejectWithValue }) => {
    // console.log()
    try {
      const response = await getLoanStats(startDate, endDate, type);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);
export const fetchLoanInsights = createAsyncThunk(
  "overview/fetchLoanInsights",
  async (
    { startDate, endDate, type, status, page },
    { dispatch, rejectWithValue }
  ) => {
    // console.log()
    try {
      const response = await getLoanStatsInsights(
        startDate,
        endDate,
        type,
        status,
        page
      );
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchStatistics.pending, (state, action) => {
      state.loadingStatistics = true;
    });
    builder.addCase(fetchStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload.data;
      state.loadingStatistics = false;
      state.error = [];
    });
    builder.addCase(fetchStatistics.rejected, (state, action) => {
      state.loadingStatistics = false;
      state.error = action.payload;
    });
    builder.addCase(fetchLoanMetrics.pending, (state, action) => {
      state.loadingLoanMetrics = true;
    });
    builder.addCase(fetchLoanMetrics.fulfilled, (state, action) => {
      state.loanMetrics = action.payload.data;
      state.loadingLoanMetrics = false;
      state.error = [];
    });
    builder.addCase(fetchLoanMetrics.rejected, (state, action) => {
      state.loadingLoanMetrics = false;
      state.error = action.payload;
    });
    builder.addCase(fetchPendingCustomers.pending, (state, action) => {
      state.loadingPendingCustomers = false;
    });
    builder.addCase(fetchPendingCustomers.fulfilled, (state, action) => {
      state.pendingCustomers = action.payload.data.users;
      state.loadingPendingCustomers = false;
      state.error = [];
    });
    builder.addCase(fetchPendingCustomers.rejected, (state, action) => {
      state.loadingPendingCustomers = false;
      state.error = action.payload;
    });
    builder.addCase(fetchPendingLoans.pending, (state, action) => {
      state.loadingPendingLoans = false;
    });
    builder.addCase(fetchPendingLoans.fulfilled, (state, action) => {
      state.pendingLoans = action.payload.data.loans;
      state.loadingPendingLoans = false;
      state.error = [];
    });
    builder.addCase(fetchPendingLoans.rejected, (state, action) => {
      state.loadingPendingLoans = false;
      state.error = action.payload;
    });
    builder.addCase(fetchLoansPie.fulfilled, (state, action) => {
      state.loansPie = action.payload.data.loansStatistics;
      state.error = [];
    });
    builder.addCase(fetchLoansPie.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchCustomersPie.fulfilled, (state, action) => {
      state.customersPie = action.payload.data.customersStatistics;
      state.error = [];
    });
    builder.addCase(fetchCustomersPie.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchCustomerStats.pending, (state, action) => {
      state.loadingCustomerStats = true;
    });
    builder.addCase(fetchCustomerStats.fulfilled, (state, action) => {
      state.customerStats = action.payload.data;
      state.error = [];
      state.loadingCustomerStats = false;
    });
    builder.addCase(fetchCustomerStats.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingCustomerStats = false;
    });
    builder.addCase(fetchLoanStats.pending, (state, action) => {
      state.loadingLoanStats = true;
    });
    builder.addCase(fetchLoanStats.fulfilled, (state, action) => {
      state.loanStats = action.payload.data;
      state.error = [];
      state.loadingLoanStats = false;
    });
    builder.addCase(fetchLoanStats.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingLoanStats = false;
    });
    builder.addCase(fetchLoanInsights.pending, (state, action) => {
      state.loadingLoanInsights = true;
    });
    builder.addCase(fetchLoanInsights.fulfilled, (state, action) => {
      state.loanInsights = action.payload.data;
      state.error = [];
      state.loadingLoanInsights = false;
    });
    builder.addCase(fetchLoanInsights.rejected, (state, action) => {
      state.error = action.payload;
      state.loadingLoanInsights = false;
    });
  },
});

export default overviewSlice.reducer;
