import { red } from "@mui/material/colors";

export const styles = {
  formHeader: {
    fontWeight: 700,
    fontSize: 20,
    // textAlign: "center",
    letterSpacing: -1,
    color: "#000",
  },
  loginFormHeader: {
    fontWeight: 700,
    fontSize: 28,
    textAlign: "center",
    letterSpacing: -1,
    color: "#000",
  },
  formSubheader: {
    fontWeight: 600,
    textAlign: "center",
    pt: 1,
    pb: 2,
    fontSize: 16,
    letterSpacing: -0.7,
    color: "#000",
  },
  formFieldHeading: {
    fontWeight: 600,
    fontSize: "13px",
    pb: 0.5,
    // mt: 2,
    letterSpacing: -0.3,
  },
  textField: {
    color: "#9A9FBF",
    borderRadius: "10px",
    "& fieldset": {
      border: 1,
      borderColor: "#B8B7B7",
      borderRadius: 1,
    },
    "& .Mui-error": {
      backgroundColor: "transparent",
    },
  },
  selectField: {
    color: "#9A9FBF",
    "& fieldset": {
      border: 1,
      borderColor: "#B8B7B7",
    },
    "& .Mui-error": {
      backgroundColor: "transparent",
    },
  },

  uploadButton: {
    input: {
      display: "none",
    },
    button: {
      m: 1,
      backgroundColor: "#9A9FBF1A",
      boxShadow: "none",
      textTransform: "Capitalize",
      borderRadius: 8,
      fontWeight: 600,
    },
  },
  kyc: {
    headerText: { fontWeight: 400, color: "#62635E", py: 1 },
    bodyText: {
      fontWeight: 700,
      color: "#494D4A",
      textTransform: "capitalize",
    },
    normalText: {
      fontWeight: 700,
      color: "#494D4A",
    },
  },
  otp: {
    headerText: { fontWeight: 400, color: "#62635E", py: 0 },
    bodyText: {
      fontWeight: 700,
      color: "#494D4A",
      textTransform: "capitalize",
    },
  },

  continueButton2: {
    color: "white",
    py: 1.5,
    textTransform: "capitalize",
    fontSize: 15,
    fontWeight: 600,
    maxHeight: 40,
  },

  textfieldSize: "small",
  sidebar: {
    color: "primary.main",
    button: {
      color: "#000",
      textTransform: "capitalize",
      pl: 2,
      py: 1,
      justifyContent: "flex-start",
      fontWeight: 600,
      borderRadius: 0,
    },
    buttonActive: {
      color: "#fff",
      backgroundColor: "primary.main",
      textTransform: "capitalize",
      py: 1,
      pl: 2,
      // my: 1,
      justifyContent: "flex-start",
      fontWeight: 600,
      borderRadius: 0,
      width: "100%",
    },
    buttonText: { pl: 1.5, fontWeight: 400, fontSize: 12 },
  },
  bvn: {
    topLeft: {
      fontWeight: 400,
      color: "#62635E",
      py: 1,
      fontSize: 16,
      textTransform: "capitalize",
    },
    bottomLeft: {
      fontWeight: 700,
      color: "#494D4A",
      fontSize: 16,
      textTransform: "capitalize",
    },
    topRight: {
      fontWeight: 400,
      color: "#62635E",
      py: 1,
      textAlign: "right",
      fontSize: 16,
      textTransform: "capitalize",
    },
    bottomRight: {
      fontWeight: 700,
      color: "#494D4A",
      textAlign: "right",
      fontSize: 16,
      textTransform: "capitalize",
    },
  },
  table: {
    text: {
      fontSize: 13,
      fontWeight: 400,
      color: "#62635E",
      display: "flex",
      alignItems: "center",
      minHeight: 45,
      textTransform: "capitalize",
      uid: {
        fontSize: 13,
        fontWeight: 400,
        color: "#62635E",
        display: "flex",
        alignItems: "center",
        minHeight: 45,
      },
      viewButton: {
        textTransform: "capitalize",
        borderColor: "#000",
        color: "#000",
        height: 26,
        fontSize: 12,
      },

      verification: {
        pending: {
          backgroundColor: "#E0EAF5",
          textAlign: "center",
          py: 0.5,
          borderRadius: 5,
          fontSize: 11,
          color: "#186FD4",
          width: 100,
          textTransform: "capitalize",
          // height: "10px",
          // textJustify: "center",
        },
        approved: {
          color: "#19AD42",
          backgroundColor: "#19AD421A",
          textAlign: "center",
          py: 0.5,
          borderRadius: 5,
          fontSize: 11,
          width: 80,
          textTransform: "capitalize",
        },
      },
      customerStatus: {
        prospect: {
          backgroundColor: "#F7F8F4",
          color: "#62635E",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 80,
          py: 0.5,
        },
        loanRejected: {
          backgroundColor: "#F7F8F4",
          color: "#62635E",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 90,
          py: 0.5,
        },
        pending: {
          backgroundColor: "#E0EAF5",
          color: "#186FD4",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 80,
          py: 0.5,
          // height: 24,
          // pt: 1,
        },
        pendingRecommendation: {
          backgroundColor: "#E0EAF5",
          color: "#186FD4",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 160,
          py: 0.5,
        },
        pendingDisbursement: {
          backgroundColor: "#E0EAF5",
          color: "#186FD4",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 160,
          py: 0.5,
        },
        pendingReview: {
          backgroundColor: "#E0EAF5",
          color: "#186FD4",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 120,
          py: 0.5,
        },
        pendingAuth: {
          backgroundColor: "#E0EAF5",
          color: "#186FD4",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 140,
          py: 0.5,
        },
        pendingOffer: {
          backgroundColor: "#E0EAF5",
          color: "#186FD4",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 90,
          py: 0.5,
        },
        pendingLien: {
          backgroundColor: "#E0EAF5",
          color: "#186FD4",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 90,
          py: 0.5,
        },
        accepted: {
          backgroundColor: "#E0EAF5",
          color: "#62635E",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 100,
          py: 0.5,
        },
        offered: {
          fontSize: 11,
          width: 60,
          color: "#cc6806",
          backgroundColor: "#cc68061A",
          borderRadius: 5,
          textAlign: "center",
          mt: 1,
          py: 0.5,
        },
        completed: {
          backgroundColor: "#19AD421A",
          color: "#19AD42",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 80,
          py: 0.5,
        },

        verified: {
          backgroundColor: "#19AD421A",
          color: "#19AD42",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 80,
          py: 0.5,
        },
        rejected: {
          backgroundColor: "#D418181A",
          color: "#D41818",
          textTransform: "capitalized",
          textAlign: "center",
          borderRadius: 8,
          fontSize: 11,
          width: 80,
          py: 0.5,
        },
      },

      status: {
        inactive: {
          color: "#62635E",
          backgroundColor: "#F7F8F4",
          textAlign: "center",
          py: 1,
          borderRadius: 5,
          fontSize: 11,
        },

        active: {
          color: "#19AD42",
          backgroundColor: "#19AD421A",
          textAlign: "center",
          py: 1,
          borderRadius: 5,
          fontSize: 11,
        },
      },

      onboardingStage: {
        completed: {
          color: "#19AD42",
          backgroundColor: "#19AD421A",
          textAlign: "center",
          py: 1,
          borderRadius: 5,
          width: 80,
          fontSize: 11,
        },
        notCompleted: {
          color: "#62635E",
          backgroundColor: "#F7F8F4",
          textAlign: "center",
          py: 1,
          borderRadius: 5,
          width: 80,
          fontSize: 11,
        },
      },
    },
  },

  customerAccountVerification: {
    sectionButton: {
      selected: {
        fontSize: 16,
        fontWeight: 800,
        color: "#000",
        textTransform: "capitalize",
        borderBottom: 3,
        borderRadius: 0,
        borderBottomColor: "#000",
        px: 0,
        mr: 2,
      },
      notSelected: {
        fontSize: 16,
        fontWeight: 400,
        color: "#969997",
        textTransform: "capitalize",
        // borderBottom: 0.5,
        borderRadius: 0,
        // borderBottomColor: "#0F1200",
        px: 0,
        mr: 2,
      },
    },
  },

  tableFilter: {
    active: {
      height: 30,
      textTransform: "capitalize",
      color: "#000",
      borderColor: "#B5B5B5",
      borderRadius: 0,
      py: 2,
      fontSize: 12,
    },
    inactive: {
      height: 30,
      textTransform: "capitalize",
      color: "#B5B5B5",
      borderColor: "#B5B5B5",
      borderRadius: 0,
      backgroundColor: "#F7F8F4",
      py: 2,
      fontSize: 12,
    },
  },

  datePicker: {
    border: 1,
    "& fieldset": { border: "none" },
    height: 40,
    borderRadius: 1,
    width: "100%",
    svg: { color: "black" },
    borderColor: "#B8B7B7",

    pr: 2,
    justifyContent: "center",
  },
  newDatePicker: {
    border: 1,
    "& fieldset": { border: "none" },
    height: 40,
    borderRadius: 1,
    width: "100%",
    svg: { color: "black" },
    borderColor: "#B8B7B7",
    justifyContent: "center",
  },
  bridgeTable: {
    status: {
      pendingVerification: {
        fontSize: 12,
        backgroundColor: "#F7F8F4",
        color: "#62635E",
        px: 1,
        borderRadius: 4,
        width: 155,
        textAlign: "center",
      },
      pendingOffer: {
        fontSize: 12,
        backgroundColor: "#0000008A",
        color: "#FFF",
        px: 1,
        borderRadius: 4,
        textAlign: "center",
        width: 90,
      },
      pendingApproval: {
        fontSize: 12,
        backgroundColor: "#0000008A",
        color: "#FFF",
        px: 1,
        borderRadius: 4,
        textAlign: "center",
        width: 110,
      },
      rejected: {
        fontSize: 12,
        backgroundColor: "#D418181A",
        color: "#D41818",
        px: 1,
        borderRadius: 4,
        width: 90,
        textAlign: "center",
      },
      payInterest: {
        fontSize: 12,
        backgroundColor: "#9747FF1A",
        color: "#9747FF",
        px: 1,
        borderRadius: 4,
        textAlign: "center",
      },
      offered: {
        fontSize: 12,
        backgroundColor: "#F2994A1A",
        color: "#F2994A",
        px: 1,
        borderRadius: 4,
        textAlign: "center",
        width: 60,
      },
      accepted: {
        fontSize: 12,
        backgroundColor: "#F2994A1A",
        color: "#F2994A",
        px: 1,
        borderRadius: 4,
        textAlign: "center",
        width: 100,
      },
      declined: {
        fontSize: 12,
        backgroundColor: "#D418181A",
        color: "#D41818",
        px: 1,
        borderRadius: 4,
        width: 110,
        textAlign: "center",
      },
      pendingLien: {
        fontSize: 12,
        backgroundColor: "#F7F8F4",
        color: "#62635E",
        px: 1,
        borderRadius: 4,
        textAlign: "center",
      },
      pendingDisbursement: {
        fontSize: 12,
        backgroundColor: "#F2994A1A",
        color: "#F2994A",
        px: 1,
        borderRadius: 4,
        textAlign: "center",
        width: 130,
      },
      completed: {
        fontSize: 12,
        backgroundColor: "#19AD421A",
        color: "#19AD42",
        px: 1,
        borderRadius: 4,
        width: 70,
        textAlign: "center",
      },
      active: {
        fontSize: 12,
        backgroundColor: "#19AD421A",
        color: "#19AD42",
        px: 1,
        borderRadius: 4,
        width: 50,
        textAlign: "center",
      },
      defaulting: {
        fontSize: 12,
        backgroundColor: "#D418181A",
        color: "#D41818",
        px: 1,
        borderRadius: 4,
        width: 70,
        textAlign: "center",
      },
      ntu: {
        fontSize: 12,
        backgroundColor: "#d6b4fc",
        color: "#301934",
        px: 1,
        borderRadius: 4,
        width: 30,
        textAlign: "center",
      },
    },
  },
  bridgeCardTop: {
    success: {
      boxShadow: "none",
      color: "#fff",
      textTransform: "capitalize",
      px: 3,
      fontSize: 12,
      fontWeight: 600,
      borderRadius: 2,
    },
    error: {
      boxShadow: "none",
      color: "#D41818",
      textTransform: "capitalize",
      px: 3,
      fontSize: 12,
      fontWeight: 600,
      borderRadius: 2,
      backgroundColor: "#FFF2F2",
      ml: 2,
      "&:hover": {
        backgroundColor: "#f6adad",
        boxShadow: "none",
      },
    },
    neutral: {
      boxShadow: "none",
      color: "#62635E",
      textTransform: "capitalize",
      px: 3,
      fontSize: 12,
      fontWeight: 600,
      borderRadius: 2,
      backgroundColor: "#F7F8F4",
      "&:hover": {
        backgroundColor: "#F2994A1A",
        boxShadow: "none",
      },
    },
  },
  addAdmin: {
    textField: {
      color: "#9A9FBF",
      borderRadius: "10px",
      "& fieldset": {
        border: 1,
        borderColor: "#B8B7B7",
        borderRadius: 3,
        width: "100%",
      },
      "& .Mui-error": {
        backgroundColor: "transparent",
      },
    },
  },
  cardTopStatus: {
    verified: {
      fontSize: 11,
      backgroundColor: "#19AD421A",
      width: 80,
      textAlign: "center",
      color: "primary.main",
      py: 0.5,
      borderRadius: 5,
    },
    pending: {
      fontSize: 11,
      backgroundColor: "#0000008A",
      width: 90,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "#fff",
    },
    pendingRecommendation: {
      fontSize: 11,
      backgroundColor: "#F7F8F4",
      width: 170,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
    },
    pendingDisbursement: {
      fontSize: 11,
      backgroundColor: "#F2994A1A",
      width: 160,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "#F2994A",
    },
    rejected: {
      backgroundColor: "#D418181A",
      color: "#D41818",
      textTransform: "capitalized",
      textAlign: "center",
      borderRadius: 8,
      fontSize: 11,
      width: 90,
      py: 0.5,
    },
    prospect: {
      fontSize: 11,
      backgroundColor: "#9747FF1A",
      width: 80,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "#9747FF",
    },
    active: {
      fontSize: 11,
      backgroundColor: "#19AD421A",
      width: 80,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "primary.main",
    },
    inactive: {
      fontSize: 11,
      backgroundColor: "#D418181A",
      width: 80,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "#D41818",
    },
    suspended: {
      fontSize: 11,
      backgroundColor: "#F2994A1A",
      width: 80,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "#F2994A",
    },
    offered: {
      fontSize: 11,
      width: 80,
      // fontWeight: 400,
      color: "#cc6806",
      backgroundColor: "#cc68061A",
      borderRadius: 5,
      textAlign: "center",
      py: 0.5,
    },
    denied: {
      fontSize: 11,
      backgroundColor: red[50],
      width: 130,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: red[700],
    },
    ntu: {
      fontSize: 11,
      backgroundColor: "#d6b4fc",
      width: 80,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "#301934",
    },
    payInterest: {
      fontSize: 11,
      backgroundColor: "#9747FF1A",
      width: 80,
      textAlign: "center",
      py: 0.5,
      borderRadius: 5,
      color: "#9747FF",
    },
    accepted: {
      fontSize: 11,
      width: 80,
      // fontWeight: 400,
      color: "#cc6806",
      backgroundColor: "#cc68061A",
      borderRadius: 5,
      textAlign: "center",
      py: 0.5,
    },
    loanRejected: {
      fontSize: 11,
      width: 80,
      // fontWeight: 400,
      color: "#cc6806",
      backgroundColor: "#cc68061A",
      borderRadius: 5,
      textAlign: "center",
      py: 0.5,
    },
    underReview: {
      fontSize: 11,
      width: 80,
      // fontWeight: 400,
      color: "#cc6806",
      backgroundColor: "#cc68061A",
      borderRadius: 5,
      textAlign: "center",
      py: 0.5,
    },
    pendingAuth: {
      fontSize: 11,
      width: 130,
      // fontWeight: 400,
      color: "#cc6806",
      backgroundColor: "#cc68061A",
      borderRadius: 5,
      textAlign: "center",
      py: 0.5,
    },
    pendingReview: {
      fontSize: 11,
      width: 120,
      // fontWeight: 400,
      color: "#186FD4",
      backgroundColor: "#E0EAF5",
      borderRadius: 5,
      textAlign: "center",
      py: 0.5,
    },
    approved: {
      fontSize: 11,
      width: 80,
      // fontWeight: 400,
      color: "#cc6806",
      backgroundColor: "#cc68061A",
      borderRadius: 5,
      textAlign: "center",
      py: 0.5,
    },
  },
  checkerCardTopStatus: {
    pending: {
      fontSize: 11,
      fontWeight: 400,
      color: "#62635E",
      backgroundColor: "#F7F8F4",
      borderRadius: 4,
      textAlign: "center",
      mt: 1,
    },
    offered: {
      fontSize: 11,
      fontWeight: 400,
      color: "#cc6806",
      backgroundColor: "#cc68061A",
      borderRadius: 4,
      textAlign: "center",
      mt: 1,
    },
  },
  overview: {
    overviewType: {
      sectionButton: {
        selected: {
          fontSize: 16,
          fontWeight: 400,
          color: "#40B869",
          textTransform: "capitalize",
          borderBottom: 2,
          borderRadius: 0,
          borderBottomColor: "#40B869",
          px: 0,
          mr: 2,
        },
        notSelected: {
          fontSize: 16,
          fontWeight: 400,
          color: "#969997",
          textTransform: "capitalize",
          // borderBottom: 0.5,
          borderRadius: 0,
          // borderBottomColor: "#0F1200",
          px: 0,
          mr: 2,
        },
      },
    },

    portfolio: {
      box: {
        border: 1,
        borderRadius: 2,
        borderColor: "#00000014",
        width: "45%",
        height: 100,
        // p: 2,
        display: "flex",
        alignItems: "center",
        mx: 1,
        px: 3,
        justifyContent: "space-between",
      },
      info: {
        fontWeight: 400,
        fontSize: 12,
        color: "#010101",
        mt: -0.5,
      },
      number: { fontWeight: 700, fontSize: 28, color: "#010101" },
      tooltip: {
        fontSize: 16,
        stroke: "#ffffff",
        strokeWidth: 0.5,
        alignSelf: "normal",
        pt: 2,
        color: "#010101",
      },
    },
    customers: {
      box: {
        border: 1,
        borderRadius: 2,
        borderColor: "#00000014",
        width: "26%",
        height: 100,
        // p: 2,
        display: "flex",
        alignItems: "center",
        mx: 1.5,
        px: 2,
        justifyContent: "space-between",
      },
      info: {
        fontWeight: 400,
        fontSize: 12,
        color: "#62635E",
        mt: -0.5,
      },
      info2: {
        fontWeight: 700,
        fontSize: 12,
        color: "#ffffff",
      },
      tooltip: {
        fontSize: 16,
        stroke: "#ffffff",
        strokeWidth: 0.5,
        alignSelf: "normal",
        pt: 2,
        color: "#94958D",
      },
      number: { fontWeight: 700, fontSize: 18 },
      number2: { fontWeight: 700, fontSize: 18, color: "#ffffff", mt: 2 },
    },
    statistics: {
      box: {
        border: 1,
        borderRadius: 2,
        borderColor: "#00000014",
        width: "32%",
        height: 100,
        // p: 2,
        display: "flex",
        alignItems: "center",
        mx: 1,
        px: 1,
        justifyContent: "space-between",
      },
      smallBox: {
        border: 1,
        borderRadius: 2,
        borderColor: "#00000014",
        width: "17%",
        height: 100,
        // p: 2,
        display: "flex",
        alignItems: "center",
        mx: 1,
        px: 1,
        justifyContent: "space-between",
      },
      smallBox2: {
        border: 1,
        borderRadius: 2,
        borderColor: "#00000014",
        width: "17%",
        height: 100,
        // p: 2,
        display: "flex",
        alignItems: "center",
        mx: 0.5,
        px: 0.5,
        justifyContent: "space-between",
      },

      mediumBox: {
        border: 1,
        borderRadius: 2,
        borderColor: "#00000014",
        width: "25%",
        height: 100,
        // p: 2,
        display: "flex",
        alignItems: "center",
        mx: 1,
        px: 1,
        justifyContent: "space-between",
      },
      info: {
        fontWeight: 400,
        fontSize: 14,
        color: "#62635E",
        mt: -0.5,
      },
      info2: {
        fontWeight: 400,
        fontSize: 12,
        color: "#62635E",
      },
      tooltip: {
        fontSize: 16,
        stroke: "#ffffff",
        strokeWidth: 0.5,
        alignSelf: "normal",
        pt: 2,
        color: "#94958D",
      },
      tooltip2: {
        fontSize: 16,
        stroke: "#ffffff",
        strokeWidth: 0.5,
        alignSelf: "normal",
        pt: 2,
        color: "#ffffff",
      },
      number: { fontWeight: 700, fontSize: 24 },
    },
    metrics: {
      box: {
        border: 0,
        borderRadius: 2,
        borderColor: "#00000014",
        width: "18%",
        height: 100,
        // p: 2,
        display: "flex",
        alignItems: "center",
        mx: 1,
        px: 1,
        justifyContent: "space-between",
      },
      info: {
        fontWeight: 400,
        fontSize: 14,
        color: "#62635E",
        mt: -0.5,
      },
      info2: {
        fontWeight: 400,
        fontSize: 12,
        color: "#62635E",
      },
      cards: {
        height: 100,
        width: 10,
        borderTopLeftRadius: 7,
        borderBottomLeftRadius: 7,
      },
      number: { fontWeight: 700, fontSize: 24 },
    },
  },
  dropdown: {
    // backgroundColor: "rgba(154, 159, 191, 0.10)",
    border: 1,
    "& fieldset": { border: "none" },
    height: 30,
    borderRadius: 1,
    width: "100%",
    svg: { color: "black" },
    borderColor: "#B8B7B7",
    px: 2,
    // textAlign: "center",
    justifyContent: "center",
    // textJustify: "center",
    fontSize: 13,
  },

  transactionModal: {
    box: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: 1,
      width: "100%",
      pb: 1,
      borderColor: "#F7F8F4",
    },
    grid: { display: "flex", justifyContent: "space-between" },
    leftText: {
      color: "#00000099",
      fontWeight: "400",
      fontSize: 14,
    },
    rightText: {
      color: "#000000DE",
      fontWeight: "400",
      fontSize: 14,
      textTransform: "capitalize",
    },
  },

  loandDetails: {
    cardBox: {
      border: 1,
      borderColor: "#00000014",
      height: 50,
      borderRadius: 2,
      px: 2,
      py: 2,
      width: "16%",
      textTop: { fontSize: 14, fontWeight: 500, mr: 2 },
      textBottom: { fontSize: 16, fontWeight: 800, ml: 2 },
    },
  },

  pieChart: {
    // position: "absolute",
    fontWeight: 800,
    fontSize: 24,
    // bottom: 95,
    textAlign: "center",
    mt: -16.5,
    // "@media (min-width: 1000px)": {
    //   left: "47%",
    // },
    // "@media (min-width: 1250px)": {
    //   left: "55%",
    // },
    // "@media (min-width: 1800)": {
    //   left: "45.3%",
    //   // Apply styles for screens with a minimum width of 1500px
    //   "& .MuiTypography-root": {
    //     // left: "45.3%",
    //   },
    // },
    // "@media (min-width: 2070)": {
    //   left: "47%",
    //   // Apply styles for screens with a minimum width of 1920px
    //   "& .MuiTypography-root": {
    //     // left: "50%",
    //   },
    // },
  },
  graph: 800,
};
