import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DeadState } from "../../../../../common/components/DeadState";

const columns = [
  {
    id: "accountName",
    label: "Account Name",
    minWidth: 50,
    align: "left",
  },
  {
    id: "accountNumber",
    label: "Account Number",
    minWidth: 50,
    align: "left",
  },
  {
    id: "bank",
    label: "Bank",
    minWidth: 50,
    align: "left",
  },
  {
    id: "primaryBank",
    label: "Primary Bank",
    minWidth: 50,
    align: "left",
  },
  // {
  //   id: "delete",
  //   label: "",
  //   minWidth: 10,
  //   align: "left",
  // },
];

const cardColumns = [
  {
    id: "bankName",
    label: "Bank",
    minWidth: 50,
    align: "left",
  },
  { id: "cardnumber", label: "Card Number", minWidth: 50, align: "left" },
  {
    id: "primaryBank",
    label: "Primary Bank",
    minWidth: 50,
    align: "left",
  },
  // {
  //   id: "delete",
  //   label: "",
  //   minWidth: 10,
  //   align: "left",
  // },
];

export const Bank = ({ customer, data }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  //  const openAddDialog = () => {
  //    setIsAddDialogOpen(true);
  //  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          // width: customer?.status === customerFilters.PENDING ? "100%" : "70%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 2, pb: 4 }}>
              <Typography
                sx={{ fontWeight: 400, color: "#62635E", py: 1, fontSize: 16 }}
              >
                BVN
              </Typography>
              <Typography
                sx={{ fontWeight: 700, color: "#494D4A", fontSize: 17 }}
              >
                {data?.bvn}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              border: 1,
              px: 2,
              py: 1,
              borderRadius: 2,
              borderColor: "#0000000D",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 2,
              }}
            >
              <Typography sx={{ color: "#62635E", fontWeight: 400 }}>
                Bank Accounts
              </Typography>
            </Box>
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                border: "none",
                boxShadow: "none",
              }}
            >
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-head": {
                          color: "#000",
                          backgroundColor: "transparent",
                          border: "4px solid black",
                        },
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            borderTop: "1px solid #B5B5B5",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "1px solid #B5B5B5",
                            fontSize: 13,
                            fontWeight: 600,
                            height: 20,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customer?.banks ? (
                      customer?.banks?.map((bank) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={bank.uid}
                          sx={{
                            "& .MuiTableCell-body": {
                              color: "#62635E",
                              backgroundColor: "#fff",
                              border: "none",
                              fontSize: 13,
                              fontWeight: 400,
                            },
                          }}
                        >
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {bank.bankAccountName}
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {bank.bankAccountNumber}
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {bank.bankName}
                          </TableCell>
                          <TableCell>
                            {bank.primaryBank ? (
                              <Typography
                                sx={{
                                  backgroundColor: "#19AD421A",
                                  color: "#19AD42",
                                  borderRadius: 3,
                                  width: 50,
                                  textAlign: "center",
                                  fontSize: 11,
                                }}
                              >
                                True
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  backgroundColor: "#D418181A",
                                  color: "#D41818",
                                  borderRadius: 3,
                                  width: 60,
                                  textAlign: "center",
                                  fontSize: 11,
                                }}
                              >
                                False
                              </Typography>
                            )}
                          </TableCell>
                          {/* <TableCell sx={{ textTransform: "capitalize" }}>
                            <IconButton>
                              <Delete sx={{ fontSize: 18, color: "#D41818" }} />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      ))
                    ) : (
                      <TableCell
                        colSpan={columns.length}
                        sx={{ textAlign: "center" }}
                      >
                        <DeadState />
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          {/* <Box
            sx={{
              border: 1,
              px: 2,
              py: 1,
              mt: 5,
              borderRadius: 2,
              borderColor: "#0000000D",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 2,
              }}
            >
              <Typography sx={{ color: "#62635E", fontWeight: 400 }}>
                Bank Cards
              </Typography>
            </Box>
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                border: "none",
                boxShadow: "none",
              }}
            >
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-head": {
                          color: "#000",
                          backgroundColor: "transparent",
                          border: "4px solid black",
                        },
                      }}
                    >
                      {cardColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            borderTop: "1px solid #B5B5B5",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "1px solid #B5B5B5",
                            fontSize: 13,
                            fontWeight: 600,
                            height: 20,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customer?.banks ? (
                      customer?.banks?.map((bank) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={bank.uid}
                          sx={{
                            "& .MuiTableCell-body": {
                              color: "#62635E",
                              backgroundColor: "#fff",
                              border: "none",
                              fontSize: 13,
                              fontWeight: 400,
                            },
                          }}
                        >
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {bank.bankAccountName}
                          </TableCell>
                          <TableCell sx={{ textTransform: "capitalize" }}>
                            {bank.bankAccountNumber}
                          </TableCell>
                          <TableCell>
                            {bank.primaryBank ? (
                              <Typography
                                sx={{
                                  backgroundColor: "#19AD421A",
                                  color: "#19AD42",
                                  borderRadius: 3,
                                  width: 50,
                                  textAlign: "center",
                                  fontSize: 11,
                                }}
                              >
                                True
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  backgroundColor: "#D418181A",
                                  color: "#D41818",
                                  borderRadius: 3,
                                  width: 60,
                                  textAlign: "center",
                                  fontSize: 11,
                                }}
                              >
                                False
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableCell
                        colSpan={columns.length}
                        sx={{ textAlign: "center" }}
                      >
                        <DeadState />
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};
