import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../../common/css/styles";
import { toTitleCase } from "../../../../../../common/constants/utility";

export const Operations = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 13 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              How business operates
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {toTitleCase(data?.modeOfOperation)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Number of locations
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.numberOfLocations ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average monthly profit
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.averageMonthlyProfit ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average monthly Salary of employees
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.totalMonthlySalaryOfEmployees ?? "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average number of Customers
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.averageDailyNumberOfCustomer ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Total number of sales in the last 6 months
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.totalSalesInLastSixMonths ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Average Monthly electricity cost
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.averageMonthlyElectricityCost ?? "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
