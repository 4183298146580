import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../../common/css/styles";
import { useNavigate } from "react-router-dom";
import { businessInfoStage } from "./stages";
import { Details } from "./stages/Details";
import { Profile } from "./stages/Profile";
import { Operations } from "./stages/Operations";
import { TeamMembers } from "./stages/TeamMembers";
import { Collateral } from "./stages/Collateral";
import { OwnerInfo } from "../../../../business/kyb/ownerInfo/OwnerInfo";
import { useSelector } from "react-redux";
import { Guarantors } from "./stages/Guarantors";
import { Documents } from "./stages/Documents";

export const BusinessInfo = ({
  businessLoan,
  businessInfo,
  ownerInfo,
  documents,
}) => {
  const loan = businessLoan?.loan;
  const [activeFilter, setActiveFilter] = useState(businessInfoStage.DETAILS);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const handleView = () =>
    navigate(
      `/sme-loans/business-loans/business-loan-overview/team-member-details`
    );
  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    // dispatch(filteredLoans({ page: newPage, filterOption: activeFilter }));
  };

  const filters = [
    { label: "Details", value: businessInfoStage.DETAILS, visible: true },
    { label: "Profile", value: businessInfoStage.PROFILE, visible: true },
    {
      label: "Operations",
      value: businessInfoStage.OPERATIONS,
      visible: true,
    },
    {
      label: "Owner Info",
      value: businessInfoStage.OWNER_INFO,
      visible: true,
    },
    {
      label: "Guarantors",
      value: businessInfoStage.GUARANTORS,
      visible: true,
    },
    {
      label: "Collateral",
      value: businessInfoStage.COLLATERAL,
      visible: true,
    },
    {
      label: "Documents",
      value: businessInfoStage.DOCUMENTS,
      visible: true,
    },
  ];

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getBusinessInfoStage = () => {
    switch (activeFilter) {
      case businessInfoStage.DETAILS:
        return <Details data={businessInfo} />;
      case businessInfoStage.PROFILE:
        return <Profile data={businessInfo} />;
      case businessInfoStage.OPERATIONS:
        return <Operations data={businessInfo} />;
      case businessInfoStage.OWNER_INFO:
        // return (
        //   <TeamMembers teamMembers={teamMembers} handleView={handleView} />
        // );
        return <OwnerInfo data={ownerInfo} />;
      case businessInfoStage.COLLATERAL:
        return <Collateral data={loan?.collaterals} />;
      case businessInfoStage.GUARANTORS:
        return <Guarantors data={loan?.guarantors} />;
      case businessInfoStage.DOCUMENTS:
        return <Documents data={loan?.documents} />;
      default:
        return <Details data={businessInfo} />;
    }
  };

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? { ...styles.tableFilter.active, fontSize: 14, p: 2 }
                  : { ...styles.tableFilter.inactive, fontSize: 14, p: 2 }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      {getBusinessInfoStage()}
    </Box>
  );
};
