import { Box, Button, Grid, IconButton, Link, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { styles } from "../../../../../../common/css/styles";

export const Collateral = ({ data }) => {
  const collateralInfo = data?.length > 0 ? data[0] : null;

  const handleDownloadFile = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name; // You can specify a default file name here

    // Append the anchor element to the document body
    document.body.appendChild(link);

    // Programmatically click the anchor element to trigger the download
    link.click();

    // Remove the anchor element from the document
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        display: "flex",
        pb: 36,
      }}
    >
      <Box sx={{ pl: 2 }}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid xs={6}>
              <Box>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", pb: 1 }}>
                    Type of collateral
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A", pb: 2 }}>
                    {collateralInfo?.typeOfCollateral ?? "Not Available"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", pb: 1 }}>
                  Value of collateral
                </Typography>
                <Typography sx={styles.kyc.bodyText}>
                  {collateralInfo?.valueOfCollateral?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }) ?? "Not Available"}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Certificate of ownership
                </Typography>
                <Button
                  sx={{
                    border: 1,
                    py: 1,
                    px: 2,
                    borderColor: "#DFF3E7",
                    borderRadius: 2,
                    backgroundColor: "#F5FAF7",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    handleDownloadFile(
                      collateralInfo?.certificateOfOwnershipUrl,
                      "certificateOfOwnership.png"
                    )
                  }
                >
                  <DownloadIcon /> Download
                </Button>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box>
                <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                  Collateral
                </Typography>
                <Button
                  sx={{
                    border: 1,
                    py: 1,
                    px: 2,
                    borderColor: "#DFF3E7",
                    borderRadius: 2,
                    backgroundColor: "#F5FAF7",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    handleDownloadFile(
                      collateralInfo?.collateralUrl,
                      "collateral.png"
                    )
                  }
                >
                  <DownloadIcon /> Download
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
