import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { styles } from "../../../../../../common/css/styles";
import { toTitleCase } from "../../../../../../common/constants/utility";

export const Details = ({ data }) => {
  return (
    <Box sx={{ pl: 2 }}>
      <Box
        sx={{
          // display: "flex",
          borderBottom: 1,
          pb: 4,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 400, color: "#62635E", py: 1 }}
                    >
                      Business Name
                    </Typography>
                    <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                      {toTitleCase(data?.businessName)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Type of Business
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {toTitleCase(
                      data?.companyType.toLowerCase().replace("_", " ")
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Business Email
                  </Typography>
                  <Typography
                    sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
                  >
                    {data?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Business Phone
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {toTitleCase(data?.phoneNumber)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          // display: "flex",
          pt: 4,
          // pb: 4,
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        color: "#62635E",
                        py: 1,
                      }}
                    >
                      Address
                    </Typography>
                    <Typography sx={styles.kyc.bodyText}>
                      {" "}
                      {toTitleCase(data?.businessStreet)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    City
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {toTitleCase(data?.businessCity)}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    LGA
                  </Typography>
                  <Typography sx={styles.kyc.bodyText}>
                    {toTitleCase(data?.localGovernmentArea)}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    State
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {toTitleCase(data?.businessState)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
