import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Avatar,
  Stack,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from "react-redux";
import { roles } from "../constants/roles";
import { gender } from "../constants/gender";
import dummyImage from "../images/avatar-guy.jpg";
import femaleDummyImage from "../images/avatar-girl.jpg";

export const MainAppBar = () => {
  const user = useSelector(({ auth }) => auth.user);

  const getRole = () => {
    switch (user?.roles[0]?.name) {
      case roles.RETAIL_CUSTOMER:
        return "Retail Customer";
      case roles.CHECKER:
        return "Checker";
      case roles.MANAGER:
        return "Manager";
      case roles.ANALYST:
        return "Analyst";
      case roles.APPROVER:
        return "Approver";
      case roles.FINANCIAL_MANAGER:
        return "Financial Manager";
      default:
        break;
    }
  };
  const getGender = () => {
    switch (user?.gender) {
      case gender.MALE:
        return dummyImage;
      case gender.FEMALE:
        return femaleDummyImage;
      default:
        break;
    }
  };

  const handleSearch = (searchTerm) => {
    console.log("Search term:", searchTerm);
  };

  return (
    <Grid container xs={12} md={12}>
      <Grid container xs={12} md={10} position="fixed" right={0} top="5vh">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{ backgroundColor: "#fff", boxShadow: "none" }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {/* <AppSearchBar /> */}
              </Typography>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {/* <IconButton
                  size="large"
                  aria-label="show 10 new notifications"
                  color="inherit"
                  sx={{
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Badge badgeContent={10} color="error">
                    <NotificationsOutlined />
                  </Badge>
                </IconButton> */}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  sx={{
                    borderRadius: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  {user?.profileImageUrl ? (
                    <Avatar src={user?.profileImageUrl} />
                  ) : (
                    // <Avatar>
                    //   <PersonIcon />
                    // </Avatar>

                    <Avatar src={getGender()} />
                  )}
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      ml: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                      {`${user?.firstName
                        .charAt(0)
                        .toUpperCase()}${user?.firstName?.substring(
                        1
                      )} ${user?.lastName
                        .charAt(0)
                        .toUpperCase()}${user?.lastName?.substring(1)}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 11,
                        fontWeight: 500,
                        // ml: -1,
                        color: "#3C3D39",
                      }}
                    >
                      {getRole()}
                    </Typography>
                  </Stack>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Grid>
    </Grid>
  );
};
