import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import sideImage from "../images/side-image.png";
import logo from "../images/new-logo.svg";

// import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";

export const PublicLayout = () => {
  return (
    <>
      <Grid
        sx={{
          display: {
            // xs: "none",
            md: "block",
            minHeight: "100vh",
            backgroundImage: `url(${sideImage}})`,
            // backgroundColor: "yellow"
          },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={false}
            md={4}
            sx={{
              display: {
                xs: "none",
                md: "block",
                // position: "relative",
                // height: "100vh",
                // backgroundImage: "url('../images/logo-sm.png')",
                // backgroundColor: "yellow"
              },
            }}
          >
            <Grid
              item
              pl={3}
              pr={2}
              pb={3}
              md={4}
              sx={{
                width: "95%",
                // height:"100%",
                color: "white",
                backgroundColor: "transparent",
                // backgroundImage: `url(${sideImage})`,
                position: "fixed",
                // flex: "",
                bottom: "0",
              }}
            >
              <Typography
                sx={{
                  // color: "primary.main",
                  // fontSize: "30px",
                  fontSize: "2.8vw",
                  fontStyle: "normal",
                  fontWeight: 800,
                  // lineHeight: "30px",
                  spacing: "30px",
                  letterSpacing: -1,
                  lineHeight: "60px",
                  wordWrap: "breakWord",
                }}
              >
                Empowering Nigerian Dreams, Simply
              </Typography>
              {/* <br /> */}
              <Typography
                sx={{
                  fontSize: 16,
                  // fontSize: "1.2vw",
                  fontStyle: "normal",
                  fontWeight: 600,
                  // lineHeight: "normal",
                  // letterSpacing: -0.5,
                  lineHeight: "27.2px",
                }}
                // color="text.secondary"
              >
                With Krevus you enjoy zero complications ensuring you can access
                the funds you need within 24 hours.
              </Typography>
            </Grid>

            <CardMedia
              sx={{ height: "100%" }}
              image={sideImage}
              title="Image"
            />

            {/* <Box component="img" src={sideImage} width="100%" height="100%" /> */}
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={8}
            sx={{ background: "#000", minHeight: "100vh" }}
          >
            <Grid item xs={0.5} md={3} />
            <Grid item xs={11} md={6}>
              <Grid container direction="column" spacing={4}>
                <Grid item xs={3} pl={8}>
                  <Box display="flex" justifyContent="center" pt={2}>
                    {/* <Box component="img" src={logo} pr={1} />
                    <Typography
                      sx={{
                        color: "primary.main",
                        fontSize: 27,
                        fontStyle: "normal",
                        fontWeight: 800,
                        lineHeight: "normal",
                        letterSpacing: -1,
                      }}
                    >
                      Tern
                    </Typography> */}
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: 27,
                        fontStyle: "normal",
                        fontWeight: 800,
                        lineHeight: "normal",
                        letterSpacing: -1,
                      }}
                    >
                      Krevus
                    </Typography>
                  </Box>
                </Grid>
                <Grid item></Grid>
                <Grid
                  item
                  xs={9}
                  sx={{ maxHeight: "2000px", minHeight: "640px" }}
                >
                  <Outlet />
                </Grid>
              </Grid>
              <br />
              {/* <Typography sx={{ color: "white" }}>Some Text Here</Typography> */}
            </Grid>
            <Grid container item xs={11} md={6}>
              {/* <Footer /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
