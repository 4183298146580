import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../../../common/css/styles";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { toTitleCase } from "../../../../../../common/constants/utility";
import { InfoDialog } from "../../../../../../common/components/InfoDialog";

export const Documents = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const handleDownloadFile = (url, name) => {
    if (!!!url) {
      setIsError(true);
      setInfoText("File not found");
      setOpen(true);
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = name; // You can specify a default file name here

      // Append the anchor element to the document body
      document.body.appendChild(link);

      // Programmatically click the anchor element to trigger the download
      link.click();

      // Remove the anchor element from the document
      document.body.removeChild(link);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          pb: 4,
          pl: 2,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid xs={6}>
                <Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 400, color: "#62635E", py: 1 }}
                    >
                      RC Number
                    </Typography>
                    <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                      {data?.rcNumber ?? "Not Available"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Tax ID Number
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {data?.taxIdNumber ?? "Not Available"}
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    CAC Number
                  </Typography>
                  <Typography sx={styles.kyc.bodyText}>
                    {data?.cacNumber ?? "Not Available"}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6}></Grid> */}
              <Grid xs={6} sx={{ mt: 2 }}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    CAC
                  </Typography>
                  <Button
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                    }}
                    onClick={() => handleDownloadFile(data?.cacUrl, "cac.png")}
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Box>
              </Grid>
              <Grid xs={6} sx={{ mt: 2 }}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Certificate of business name
                  </Typography>
                  <Button
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      handleDownloadFile(
                        data?.certificateOfBusinessNameUrl,
                        "businessName.png"
                      )
                    }
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    MEMART
                  </Typography>
                  <Button
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      handleDownloadFile(data?.memartUrl, "memart.png")
                    }
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Box>{" "}
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    SCUML
                  </Typography>
                  <Button
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      handleDownloadFile(data?.scumlUrl, "scuml.png")
                    }
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Box>{" "}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          borderBottom: 1,
          pb: 4,
          pl: 2,
          borderBottomColor: "#F0F1ED",
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Board resolution
                  </Typography>
                  <Button
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      handleDownloadFile(
                        data?.boardResolutionUrl,
                        "boardResolution.png"
                      )
                    }
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Box>
              </Grid>
              <Grid xs={6}>
                <Box>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                    Cheque
                  </Typography>
                  <Button
                    sx={{
                      border: 1,
                      py: 1,
                      px: 2,
                      borderColor: "#DFF3E7",
                      borderRadius: 2,
                      backgroundColor: "#F5FAF7",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      handleDownloadFile(data?.chequeUrl, "cheque.png")
                    }
                  >
                    <DownloadIcon /> Download
                  </Button>
                </Box>{" "}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <InfoDialog
        open={open}
        error={isError}
        text={infoText}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
};
