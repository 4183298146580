import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Logout } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../features/auth/authSlice";
import { SidebarItem } from "./SidebarItem";
import { getMenuItems } from "../constants/menu";

export const Sidebar = () => {
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const role = user?.roles[0].name;
  const menuItems = getMenuItems(role);

  const handleLogout = () => {
    dispatch(userLogout());
  };

  return (
    <>
      {/* Sidebar */}
      <Grid
        container
        xs={false}
        md={2}
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: "#fff",
          height: "95vh",
          display: { md: "block", sm: "none", xs: "none" },
          position: "fixed",
          top: "5vh",
        }}
      >
        <Grid
          item
          //   fullWidth
          sx={{ position: "fixed", height: "95vh", width: "16.7%" }}
        >
          {/* Logo */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              px: 2,
              my: 2,
            }}
          >
            {/* <img src={logo} alt="logo" /> */}
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: 20,
                pl: 1,
                color: "primary.main",
              }}
            >
              Krevus
            </Typography>
          </Box>
          {/* Content */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flex-start",
              //   px: 1,
              my: 1,
            }}
          >
            {menuItems
              .filter((item) => item.visible)
              .map((item) => (
                <SidebarItem key={item.id} item={item} />
              ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flex-start",
              px: 2,
              my: 1,
              position: "absolute",
              bottom: 60,
              width: "85%",
            }}
          >
            <Button
              onClick={handleLogout}
              sx={{
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#00000014" },
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Box
                width="30px"
                height="30px"
                display="flex"
                alignItems="center"
              >
                <Logout
                  sx={{ color: "#000000", fontSize: 20, color: "#616663" }}
                />
              </Box>
              <Typography
                sx={{ color: "#616663", pl: 1, fontWeight: 500, fontSize: 12 }}
              >
                Logout
              </Typography>
            </Button>
          </Box>
          {/* <Typography
            sx={{ fontSize: 10, position: "absolute", bottom: 30, right: 20 }}
          >
            Last Login Session at 18:36 12/05/2023
          </Typography> */}
        </Grid>
      </Grid>
    </>
  );
};
