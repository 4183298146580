import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { WalletTransactionsTable } from "./WalletTransactionsTable";
import {
  fetchWalletTransaction,
  fetchWalletTransactions,
  searchWalletTransactions,
  searchWalletTransactionsByDate,
} from "./walletSlice";
import { FilterSection } from "../../common/components/FilterSection";
import { format } from "date-fns";

export const WalletTransactions = () => {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const wallets = useSelector((state) => state.wallets);
  const dispatch = useDispatch();

  const handleViewClick = (uid) => {
    dispatch(fetchWalletTransaction(uid));
    setOpen(true);
  };

  const handleCloseView = () => setOpen(false);

  const handleSearch = (searchTerm) => {
    dispatch(searchWalletTransactions({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(fetchWalletTransactions(newPage));
  };

  const onFilterSubmit = (data) => {
    dispatch(searchWalletTransactionsByDate({ page: 1, ...data }));
  };

  const csvData = wallets.transactions.reduce((res, item) => {
    res.push({
      transactionID: item.uid,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      phoneNumber: item.user.phoneNumber,
      narration: item.narration,
      amount: item.amount,
      timestamp: format(new Date(item.updatedAt), "dd/MM/yyyy hh:mm:ss aa"),
    });
    return res;
  }, []);

  useEffect(() => {
    dispatch(fetchWalletTransactions(1));
  }, []);

  return (
    <>
      <SectionHeader headerText="Wallet Transactions" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FilterSection
              onSubmit={onFilterSubmit}
              csvData={csvData}
              filename="WalletsTransactions"
            />
          </Stack>
          <WalletTransactionsTable
            wallets={wallets}
            page={page}
            handlePageChange={handlePageChange}
            open={open}
            handleViewClick={handleViewClick}
            handleCloseView={handleCloseView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};
