import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Stack, FormControl, Select, MenuItem } from "@mui/material";
import { Add } from "@mui/icons-material";
import { styles } from "../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { filteredLoans, searchRetailLoans } from "./store/retailLoansSlice";
import { retailFilters } from "./constants/retailFilters";
import { UpdatedLoansTable } from "./UpdatedLoansTable";
import { useNavigate } from "react-router-dom";
import { loanType } from "../smeLoans/constants/loanType";
import { overviewFilters } from "../overview/overviewFilters";
import {
  filteredBridgeLoans,
  searchBridgeLoans,
} from "../bridgeFinance/store/bridgeFinanceLoansSlice";
import {
  filteredSmeLoans,
  searchBusinessLoans,
} from "../smeLoans/businessLoan/store/businessLoansSlice";
import { bridgeFinanceFilters } from "../bridgeFinance/constants/bridgeFinanceFilters";

export const UpdatedLoans = () => {
  const bridgeLoans = useSelector((state) => state.bridgeLoans);
  const retailLoans = useSelector((state) => state.retailLoans);
  const businessLoans = useSelector((state) => state.businessLoans);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState(overviewFilters.LAST7DAYS);
  const [loanStatusFilter, setLoanStatusFilter] = useState("");
  const [loanTypeFilter, setLoanTypeFilter] = useState(
    loanType.BRIDGE_FINANCING
  );

  const handleLoanStatusFilterChange = (event) => {
    setLoanStatusFilter(event.target.value);
  };

  const handleLoanTypeFilterChange = (event) => {
    setLoanTypeFilter(event.target.value);
  };

  const handleStatisticsFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearch = (searchTerm) => {
    switch (loanTypeFilter) {
      case loanType.BRIDGE_FINANCING:
        dispatch(searchBridgeLoans({ page: 1, searchTerm }));
        break;
      case loanType.RETAIL:
        dispatch(searchRetailLoans(1, searchTerm));
        break;
      case loanType.BUSINESS:
        dispatch(searchBusinessLoans(1, searchTerm));
        break;
      case loanType.ASSET_FINANCING:
        dispatch(searchBusinessLoans(1, searchTerm));
        break;
      default:
        dispatch(searchBridgeLoans({ page: 1, searchTerm }));
        break;
    }
  };

  const loanTypes = [
    {
      label: "Bridge Financing",
      value: loanType.BRIDGE_FINANCING,
    },
    { label: "Retail Loans", value: loanType.RETAIL },
    { label: "Business Loans", value: loanType.BUSINESS },
    { label: "Asset Financing", value: loanType.ASSET_FINANCING },
  ];

  const loanStatusFilters = [
    { label: "All", value: retailFilters.ALL, visible: true },
    {
      label: "Pending Recommendation",
      value: retailFilters.PENDING_RECOMMENDATION,
      visible: true,
    },
    {
      label: "Pending Disbursement",
      value: retailFilters.PENDING_DISBURSEMENT,
      visible: true,
    },
    {
      label: "Pending Offer",
      value: retailFilters.PENDING_OFFER,
      visible: true,
    },
    {
      label: "Pay Interest",
      value: bridgeFinanceFilters.PAY_INTEREST,
      visible: loanTypeFilter === loanType.BRIDGE_FINANCING ? true : false,
    },
    {
      label: "Pending Lien",
      value: bridgeFinanceFilters.PENDING_LIEN,
      visible: loanTypeFilter === loanType.BRIDGE_FINANCING ? true : false,
    },
    {
      label: "Offered",
      value: retailFilters.OFFERED,
      visible: true,
    },
    {
      label: "Rejected Offers",
      value: retailFilters.OFFER_REJECTED,
      visible: true,
    },
    {
      label: "Request Declined",
      value: retailFilters.REQUEST_DECLINED,
      visible: true,
    },
    { label: "Active", value: retailFilters.ACTIVE, visible: true },
    { label: "Defaulting", value: retailFilters.DEFAULTING, visible: true },
    { label: "Completed", value: retailFilters.COMPLETED, visible: true },
    { label: "NTUs", value: retailFilters.NTU, visible: true },
  ];

  const ranges = [
    { label: "Last 7 days", value: overviewFilters.LAST7DAYS },
    {
      label: "Last 14 days",
      value: overviewFilters.LAST14DAYS,
    },
    { label: "Last 1 month", value: overviewFilters.ONE_MONTH },
    { label: "Last 2 months", value: overviewFilters.TWO_MONTHS },
    { label: "Last 3 months", value: overviewFilters.THREE_MONTHS },
    { label: "Custom Date", value: overviewFilters.CUSTOM },
  ];

  useEffect(() => {
    switch (loanTypeFilter) {
      case loanType.BRIDGE_FINANCING:
        dispatch(
          filteredBridgeLoans({ page: 1, filterOption: loanStatusFilter })
        );
        break;
      case loanType.RETAIL:
        dispatch(filteredLoans({ page: 1, filterOption: loanStatusFilter }));
        break;
      case loanType.BUSINESS:
        dispatch(
          filteredSmeLoans({
            page: 1,
            loanType: loanType.BUSINESS,
            filterOption: loanStatusFilter,
          })
        );
        break;
      case loanType.ASSET_FINANCING:
        dispatch(
          filteredSmeLoans({
            page: 1,
            loanType: loanType.ASSET_FINANCING,
            filterOption: loanStatusFilter,
          })
        );
        break;
      default:
        dispatch(
          filteredBridgeLoans({ page: 1, filterOption: loanStatusFilter })
        );
        break;
    }
  }, [filter, loanStatusFilter, loanTypeFilter]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    switch (loanTypeFilter) {
      case loanType.BRIDGE_FINANCING:
        dispatch(
          filteredBridgeLoans({
            page: newPage,
            filterOption: loanStatusFilter,
          })
        );
        break;
      case loanType.RETAIL:
        dispatch(
          filteredLoans({ page: newPage, filterOption: loanStatusFilter })
        );
        break;
      case loanType.BUSINESS:
        dispatch(
          filteredSmeLoans({
            page: newPage,
            loanType: loanType.BUSINESS,
            filterOption: loanStatusFilter,
          })
        );
        break;
      case loanType.ASSET_FINANCING:
        dispatch(
          filteredSmeLoans({
            page: newPage,
            loanType: loanType.ASSET_FINANCING,
            filterOption: loanStatusFilter,
          })
        );
        break;
      default:
        dispatch(
          filteredBridgeLoans({
            page: newPage,
            filterOption: loanStatusFilter,
          })
        );
        break;
    }
  };

  const handleView = (uid) => navigate(`/loans/overview/${uid}`);

  return (
    <>
      <SectionHeader headerText="Loans" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            {/* <FormControl sx={{ minWidth: 120, my: 2 }}>
              <Select
                value={activeFilter}
                size="small"
                // onChange={handleLoanMetricsDateFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ ...styles.dropdown, py: 2, height: 40, fontWeight: 900 }}
              >
                {filters
                  .filter((filter) => filter.visible === true)
                  .map((filter) => (
                    <MenuItem
                      key={filter.value}
                      sx={{ fontSize: 12, fontWeight: 700 }}
                      onClick={() => handleFilter(filter.value)}
                      variant="outlined"
                      value={filter.value}
                    >
                      {filter.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              <FormControl sx={{ mr: 2.5, mt: 1 }}>
                <Select
                  value={loanTypeFilter}
                  size="small"
                  onChange={handleLoanTypeFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {loanTypes?.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ mr: 2.5, mt: 1 }}>
                <Select
                  value={loanStatusFilter}
                  size="small"
                  onChange={handleLoanStatusFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {loanStatusFilters
                    ?.filter((status) => status.visible === true)
                    ?.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl sx={{ mr: 3.5, mt: 1 }}>
                <Select
                  value={filter}
                  size="small"
                  onChange={handleStatisticsFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {ranges?.map((range) => (
                    <MenuItem key={range.value} value={range.value}>
                      {range.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
          {/* <Box sx={{ py: 2 }}>
            {filters
              .filter((filter) => filter.visible === true)
              .map((filter) => (
                <Button
                  key={filter.value}
                  onClick={() => handleFilter(filter.value)}
                  variant="outlined"
                  sx={
                    activeFilter === filter.value
                      ? styles.tableFilter.active
                      : styles.tableFilter.inactive
                  }
                >
                  {filter.label}
                </Button>
              ))}
          </Box> */}
          <UpdatedLoansTable
            loans={
              loanTypeFilter === loanType.BRIDGE_FINANCING
                ? bridgeLoans
                : loanTypeFilter === loanType.RETAIL
                ? retailLoans
                : businessLoans
            }
            loanTypeFilter={loanTypeFilter}
            loanStatus={loanStatusFilter}
            page={page}
            handlePageChange={handlePageChange}
            handleView={handleView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};
