export const retailFilters = {
  ALL: "",
  PENDING_RECOMMENDATION: "PENDING_RECOMMENDATION",
  PENDING_DISBURSEMENT: "PENDING_DISBURSEMENT",
  PENDING_OFFER: "PENDING_OFFER",
  OFFERED: "OFFERED",
  ACTIVE: "ACTIVE",
  OFFER_REJECTED: "OFFER_REJECTED",
  DEFAULTING: "DEFAULTING",
  DECLINED: "DECLINED",
  COMPLETED: "COMPLETED",
  NTUS: "NTUS",
  LOST: "LOST",
};

export const loansDetailsFilters = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  EMPLOYMENT: "EMPLOYMENT",
  BANK_INFO: "BANK_INFO",
  NEXT_OF_KIN: "NEXT_OF_KIN",
  IDENTIFICATION: "IDENTIFICATION",
  DOCUMENT: "DOCUMENT",
};

export const repaymentFilters = {
  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",
  PENDING: "PENDING",
};
