import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DisbursementsTable } from "./DisbursementsTable";
import { FilterSection } from "../../common/components/FilterSection";
import {
  fetchDisbursements,
  searchDisbursementsTransactions,
  searchDisbursementsTransactionsByDate,
} from "./disbursementsSlice";
import { format } from "date-fns";

export const Disbursements = () => {
  const [page, setPage] = useState(0);
  const disbursements = useSelector((state) => state.disbursements);

  const dispatch = useDispatch();

  const handleSearch = (searchTerm) => {
    dispatch(searchDisbursementsTransactions({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(fetchDisbursements(newPage));
  };

  const onFilterSubmit = (data) => {
    dispatch(searchDisbursementsTransactionsByDate({ page: 1, ...data }));
  };

  const csvData = disbursements.transactions?.reduce((res, item) => {
    res.push({
      transactionID: item.uid,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      phoneNumber: item.user.phoneNumber,
      narration: item.narration,
      amount: item.amount,
      timestamp: format(new Date(item.updatedAt), "dd/MM/yyyy hh:mm:ss aa"),
    });
    return res;
  }, []);

  useEffect(() => {
    dispatch(fetchDisbursements(1));
  }, []);

  return (
    <>
      <SectionHeader headerText="Disbursement" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FilterSection
              onSubmit={onFilterSubmit}
              csvData={csvData}
              filename="Disbursements"
            />
          </Stack>
          <DisbursementsTable
            disbursements={disbursements}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      </WhiteBox>
    </>
  );
};
