import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { styles } from "../../../../common/css/styles";

export const CreditDetails = ({ open, handleClose }) => {
  const wallets = useSelector((state) => state.wallets);
  const transaction = wallets.transaction;

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ minWidth: 400 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 0,
            mx: 2.5,
            mt: 2,
          }}
        >
          <DialogTitle sx={{ color: "#000", p: 0 }}>Credit Details</DialogTitle>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <Close sx={{ color: "#000", p: 0 }} />
          </IconButton>
        </Box>

        <DialogContent>
          {/* {wallets.loadingTransaction ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : ( */}
          <Grid container direction="column" spacing={2}>
            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={styles.transactionModal.box}>
                <Typography sx={styles.transactionModal.leftText}>
                  Amount
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  {250000?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={styles.transactionModal.box}>
                <Typography sx={styles.transactionModal.leftText}>
                  Beneficiary Account
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  5843222345
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={styles.transactionModal.box}>
                <Typography sx={styles.transactionModal.leftText}>
                  Account Name
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  Mike Kent
                </Typography>
              </Box>
            </Grid>

            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={styles.transactionModal.box}>
                <Typography sx={styles.transactionModal.leftText}>
                  Bank
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  Access Bank
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={styles.transactionModal.box}>
                <Typography sx={styles.transactionModal.leftText}>
                  Transaction Type
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  NIP
                </Typography>
              </Box>
            </Grid>

            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={styles.transactionModal.box}>
                <Typography sx={styles.transactionModal.leftText}>
                  Transaction Status
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  Debit
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={styles.transactionModal.box}>
                <Typography sx={styles.transactionModal.leftText}>
                  Narration
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  Loan Payment
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={styles.transactionModal.grid}>
              <Box sx={{ ...styles.transactionModal.box, border: "none" }}>
                <Typography sx={styles.transactionModal.leftText}>
                  Transaction Date
                </Typography>
                <Typography sx={styles.transactionModal.rightText}>
                  {/* {transaction?.updatedAt &&
                    format(
                      new Date(transaction?.updatedAt),
                      "dd/MM/yyyy hh:mm:ss aa"
                    )} */}
                  23/09/2023 12:23
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* // )} */}
        </DialogContent>
      </Box>
    </Dialog>
  );
};
