import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { styles } from "../../../common/css/styles";
import { Add } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { feedback } from "../../../common/constants/feedback";
import { clearLoanFeedback, closeLoan } from "../store/bridgeFinanceLoanSlice";

const defaultValues = {
  proofOfRepayment: null,
};

const schema = yup.object().shape({
  proofOfRepayment: yup.mixed().required("Please upload proof of repayment"),
});

export const CompleteLoan = ({ openModal, handleCloseModal }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const bridgeLoan = useSelector((state) => state.bridgeLoan);
  const dispatch = useDispatch();

  const handleInfoClose = () => {
    dispatch(clearLoanFeedback());
    setOpen(false);
  };

  const onSubmit = (data) => {
    dispatch(
      closeLoan({
        loanUid: bridgeLoan.loan.uid,
        proofOfRepayment: data.proofOfRepayment,
      })
    );

    handleCloseModal();
  };

  useEffect(() => {
    if (bridgeLoan.feedback === feedback.SUCCESSFUL) {
      setIsError(false);
      setInfoText("Loan request successfully closed");
      setOpen(true);
    } else if (bridgeLoan.feedback === feedback.FAILED) {
      setIsError(true);
      setInfoText(bridgeLoan.error);
      setOpen(true);
    }
  }, [bridgeLoan.feedback]);

  return (
    <>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // minWidth: "500px",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "280px", display: "flex", justifyContent: "center" }}
            ></Box>
            <DialogContentText>
              <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
                Mark as completed
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
              >
                Please upload the customer's proof of payment to continue.
              </Typography>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ pt: 2 }}
              >
                <Controller
                  name="proofOfRepayment"
                  control={control}
                  render={({ field }) => (
                    <label htmlFor="file-upload">
                      <input
                        {...field}
                        accept="image/*,.pdf"
                        style={styles.uploadButton.input}
                        id="file-upload"
                        type="file"
                        value={selectedFile}
                        onChange={(e) => {
                          setSelectedFile(e.target.value);
                          field.onChange(e.target.files);
                        }}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        style={{
                          ...styles.uploadButton.button,
                          width: "100%",
                          p: 0,
                          borderRadius: 2,
                        }}
                        startIcon={<Add sx={{ color: "primary.main" }} />}
                      >
                        {!!getValues("proofOfRepayment")
                          ? getValues("proofOfRepayment")[0]?.name
                          : "Upload"}
                      </Button>
                    </label>
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleCloseModal}
                    sx={{
                      ...styles.bridgeCardTop.error,
                      fontSize: 11,
                      px: 1,
                      mx: 1,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!selectedFile}
                    sx={{
                      ...styles.bridgeCardTop.success,
                      fontSize: 11,
                      px: 1,
                    }}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
      <InfoDialog
        open={open}
        error={isError}
        text={infoText}
        handleClose={handleInfoClose}
      />
    </>
  );
};
