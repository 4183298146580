import { ArrowBack, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DeadState } from "../../common/components/DeadState";
import { SectionHeaderWithButton } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { styles } from "../../common/css/styles";
import { getKycInfo, resetKyc } from "../kyc/kycSlice";
import { repaymentFilters, retailFilters } from "./constants/retailFilters";
import { clearCustomer, fetchCustomer } from "../customers/store/customerSlice";
import { format } from "date-fns";
import { RepaymentHistory } from "./details/repayment/RepaymentHistory";
import { stage } from "./details/repayment";
import { RepaymentSchedule } from "./details/repayment/RepaymentSchedule";
import { Disbursement } from "./details/repayment/Disbursement";
import { Personal } from "./details/personal/Personal";
import { loanType } from "../smeLoans/constants/loanType";

const tempLoanList = [
  // {
  //   loanAmount: "N2,500,000",
  //   date: "23/09/2023",
  //   source: "Wallet",
  //   status: "Paid",
  // },
];

const columns = [
  {
    id: "amount",
    label: "Amount",
    minWidth: 50,
    align: "left",
  },
  {
    id: "date",
    label: "Repayment Date",
    minWidth: 50,
    align: "left",
  },
  {
    id: "source",
    label: "Source",
    minWidth: 50,
    align: "left",
  },
  {
    id: "repaymentStatus",
    label: "Status",
    minWidth: 50,
    align: "left",
  },
];

const getStatusDisplay = (status) => {
  switch (status) {
    case retailFilters.COMPLETED:
      return (
        <Typography sx={styles.cardTopStatus.verified}>Completed</Typography>
      );
    case retailFilters.ACTIVE:
      return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
    case retailFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.cardTopStatus.pendingRecommendation}>
          Pending Recommendation
        </Typography>
      );
    case retailFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.cardTopStatus.pending}>Pending Offer</Typography>
      );
    case retailFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.cardTopStatus.pendingDisbursement}>
          Pending Disbursement
        </Typography>
      );
    case retailFilters.REQUEST_DECLINED:
      return <Typography sx={styles.cardTopStatus.denied}>Declined</Typography>;
    case retailFilters.DEFAULTING:
      return (
        <Typography sx={styles.cardTopStatus.denied}>Defaulting</Typography>
      );
    case retailFilters.OFFERED:
      return <Typography sx={styles.cardTopStatus.offered}>Offered</Typography>;
    case retailFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.cardTopStatus.rejected}>
          Offer Rejected
        </Typography>
      );
    case retailFilters.NTU:
      return <Typography sx={styles.cardTopStatus.ntu}>NTU</Typography>;
    default:
      break;
  }
};

export const UpdatedLoanDetails = ({ loan }) => {
  const bridgeLoan = useSelector(({ bridgeLoan }) => bridgeLoan.loan);
  const businessLoan = useSelector(({ businessLoan }) => businessLoan.loan);
  const handleTabChange = (stage) => setCurrentStage(stage);
  const [currentStage, setCurrentStage] = useState(stage.PERSONAL);
  const { loanid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loanOfferTotalRepayment = loan?.loanOfferTotalRepayment ?? 0;
  const totalCollectedAmount = loan?.totalCollectedAmount ?? 0;
  const pendingAmount = loanOfferTotalRepayment - totalCollectedAmount;
  const repaymentPercentage =
    loanOfferTotalRepayment === 0
      ? 0
      : (totalCollectedAmount / loanOfferTotalRepayment) * 100;

  const handleBackToTable = () => navigate(-1);

  const getKYCStage = () => {
    switch (currentStage) {
      case stage.REPAYMENT_HISTORY:
        return <RepaymentHistory />;
      case stage.REPAYMENT_SCHEDULE:
        return <RepaymentSchedule />;
      case stage.DISBURSEMENT:
        return <Disbursement />;
      case stage.KYC:
        return <Personal />;
      case stage.PENALTY:
        return <Personal />;
      default:
        return <RepaymentHistory />;
    }
  };

  const getRepaymentStatusDisplay = (status) => {
    switch (status) {
      case repaymentFilters.SUCCESSFUL:
        return (
          <Typography sx={styles.cardTopStatus.active}>Successful</Typography>
        );
      case repaymentFilters.FAILED:
        return <Typography sx={styles.cardTopStatus.denied}>Failed</Typography>;
      case repaymentFilters.PENDING:
        return (
          <Typography sx={styles.cardTopStatus.pending}>Pending</Typography>
        );
      default:
        return (
          <Typography sx={styles.cardTopStatus.active}>Successful</Typography>
        );
    }
  };

  return (
    <>
      <SectionHeaderWithButton
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title="Back to loan history table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Loan History
          </Typography>
        }
      />

      <Box
        sx={{
          p: 2,
          pt: 4,
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Grid
          container
          sx={{
            display: {
              // xs: "none",
              md: "block",
              // backgroundColor: "yellow"
            },
            borderBottom: 1,
            mt: 2,
            pb: 3,
            borderBottomColor: "#F0F1ED",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack>
              <Box sx={{ display: "flex" }}>
                <Stack sx={{ ml: 4 }}>
                  <Typography sx={{ fontWeight: 700, fontSize: 28 }}>
                    Loan Details
                  </Typography>
                  {getStatusDisplay(loan?.status)}
                </Stack>
              </Box>
              {/* <Typography sx={{ mt: 1.2 }}>{setStatus()}</Typography> */}
            </Stack>
            <Box>
              <Stack>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 24,
                    color: "#3C3D39",
                    textAlign: "Right",
                  }}
                >
                  Disbursed:
                  {loan?.loanOfferAmount?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }) ?? "₦0.00"}{" "}
                  <Typography
                    component="sup"
                    sx={{
                      display: "inline",
                      fontWeight: 700,
                      fontSize: 10,
                      color: "#3C3D39",
                      textAlign: "Right",
                    }}
                  >
                    ({loan?.loanOfferInterestRate}%/Month)
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 600,
                    color: "#62635E",
                    textAlign: "Right",
                    mr: 0.3,
                  }}
                >
                  <span style={{ color: "#27AE60" }}> Duration:</span>{" "}
                  {loan?.loanTenure}{" "}
                  {loan?.loanTenure === 1 ? "month" : "months"}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>

        <Box
          item
          sx={{
            width: "100%",

            borderRadius: 2,
            my: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
            <Typography sx={{ px: 2, pl: 3.5, fontWeight: 600, fontSize: 20 }}>
              Loan Details
            </Typography>
          </Box>

          <Box sx={{ px: 2.5 }}>
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "100%",
                }}
              >
                <Box sx={styles.overview.statistics.smallBox2}>
                  <Stack sx={{ px: 1 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Amount Repaid
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {totalCollectedAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Defaulting penalty fee"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>{" "}
                <Box sx={styles.overview.statistics.smallBox2}>
                  <Stack sx={{ pl: 2 }}>
                    {" "}
                    <Typography sx={styles.overview.statistics.info}>
                      Outstanding
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {totalCollectedAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Amount repaid so far"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox2}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Next Due Date
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {loan?.nextRepaymentDate
                        ? format(
                            new Date(loan?.nextRepaymentDate),
                            "dd/MM/yyyy"
                          )
                        : "N/A"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Repayment next due date"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox2}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Penalty
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {pendingAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Repayment outstanding amount"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox2}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Total Interest
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {pendingAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Repayment outstanding amount"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
                <Box sx={styles.overview.statistics.smallBox2}>
                  <Stack sx={{ pl: 2 }}>
                    <Typography sx={styles.overview.statistics.info}>
                      Total Repayment
                    </Typography>
                    <Typography sx={styles.overview.statistics.number}>
                      {totalCollectedAmount?.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      }) ?? "₦0"}
                    </Typography>
                  </Stack>

                  <Tooltip
                    title="Total amount to be repaid"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip} />
                  </Tooltip>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          p: 2,
          pt: 4,
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Grid item container xs={12} md={12} sx={{ p: 2, minHeight: "100vh" }}>
          <WhiteBox>
            <Box sx={{ p: 2 }}>
              <Box sx={{ borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
                <Button
                  onClick={() => handleTabChange(stage.PERSONAL)}
                  sx={
                    currentStage === stage.PERSONAL
                      ? styles.customerAccountVerification.sectionButton
                          .selected
                      : styles.customerAccountVerification.sectionButton
                          .notSelected
                  }
                >
                  Repayment History
                </Button>
                <Button
                  onClick={() => handleTabChange(stage.REPAYMENT_SCHEDULE)}
                  sx={
                    currentStage === stage.REPAYMENT_SCHEDULE
                      ? styles.customerAccountVerification.sectionButton
                          .selected
                      : styles.customerAccountVerification.sectionButton
                          .notSelected
                  }
                >
                  Repayment Schedule
                </Button>
                <Button
                  onClick={() => handleTabChange(stage.DISBURSEMENT)}
                  sx={
                    currentStage === stage.DISBURSEMENT
                      ? styles.customerAccountVerification.sectionButton
                          .selected
                      : styles.customerAccountVerification.sectionButton
                          .notSelected
                  }
                >
                  Disbursement
                </Button>
                <Button
                  onClick={() => handleTabChange(stage.KYC)}
                  sx={
                    currentStage === stage.KYC
                      ? styles.customerAccountVerification.sectionButton
                          .selected
                      : styles.customerAccountVerification.sectionButton
                          .notSelected
                  }
                >
                  KYC
                </Button>
                <Button
                  onClick={() => handleTabChange(stage.PENALTY)}
                  sx={
                    currentStage === stage.PENALTY
                      ? styles.customerAccountVerification.sectionButton
                          .selected
                      : styles.customerAccountVerification.sectionButton
                          .notSelected
                  }
                >
                  Penalty
                </Button>
              </Box>

              <Box
                sx={{
                  display: "",
                  justifyContent: "space-between",
                  width: "100%",
                  //   alignItems: "normal",
                }}
              >
                {getKYCStage()}
              </Box>
            </Box>
          </WhiteBox>
        </Grid>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
          <Typography sx={{ px: 2, pl: 3.5, fontWeight: 600, fontSize: 20 }}>
            Repayment History
          </Typography>
        </Box> */}
      </Box>
    </>
  );
};
