import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styles } from "../../../common/css/styles";
import checkIcon from "../../../common/images/check-icon.svg";
import closeIcon from "../../../common/images/close-1con.svg";
import { retailFilters } from "../constants/retailFilters";
import { RecommendForOffer } from "./RecommendForOffer";
import { RecommendForDecline } from "./RecommendForDecline";
import { roles } from "../../../common/constants/roles";
import { Decline } from "./Decline";
import { MakeOffer } from "./MakeOffer";
import { DisburseConfirmation } from "./DisburseConfirmation";

export const CardSide = ({ customer, loan, kycData }) => {
  const user = useSelector(({ auth }) => auth.user);
  const role = user?.roles[0]?.name;

  const [openRecommendForOffer, setOpenRecommendForOffer] = useState(false);
  const [openRecommendForDecline, setOpenRecommendForDecline] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openMakeOffer, setOpenMakeOffer] = useState(false);
  const [openDisburseLoan, setOpenDisburseLoan] = useState(false);

  const setStatus = () => {
    switch (loan?.status) {
      case retailFilters.COMPLETED:
        return (
          <Typography sx={styles.cardTopStatus.verified}>Completed</Typography>
        );
      case retailFilters.ACTIVE:
        return <Typography sx={styles.cardTopStatus.active}>Active</Typography>;
      case retailFilters.PENDING_RECOMMENDATION:
        return (
          <Typography sx={styles.cardTopStatus.pendingRecommendation}>
            Pending Recommendation
          </Typography>
        );
      case retailFilters.PENDING_OFFER:
        return (
          <Typography sx={styles.cardTopStatus.pending}>
            Pending Offer
          </Typography>
        );
      case retailFilters.PENDING_DISBURSEMENT:
        return (
          <Typography sx={styles.cardTopStatus.pendingDisbursement}>
            Pending Disbursement
          </Typography>
        );
      case retailFilters.REQUEST_DECLINED:
        return (
          <Typography sx={styles.cardTopStatus.denied}>Declined</Typography>
        );
      case retailFilters.DEFAULTING:
        return (
          <Typography sx={styles.cardTopStatus.denied}>Defaulting</Typography>
        );
      case retailFilters.OFFERED:
        return (
          <Typography sx={styles.cardTopStatus.offered}>Offered</Typography>
        );
      case retailFilters.OFFER_REJECTED:
        return (
          <Typography sx={styles.cardTopStatus.rejected}>
            Offer Rejected
          </Typography>
        );
      case retailFilters.NTU:
        return <Typography sx={styles.cardTopStatus.ntu}>NTU</Typography>;
      default:
        break;
    }
  };

  const shouldSeeRecommendButtons = () => {
    if (
      loan?.status === retailFilters.PENDING_RECOMMENDATION
      //  && user?.uid === loan?.recommender?.uid
    )
      return true;
    else return false;
  };

  const shouldSeeOfferButtons = () => {
    if (
      role === roles.MANAGER &&
      loan?.status === retailFilters.PENDING_OFFER
      // && user?.uid === loan?.offerer?.uid
    )
      return true;
    else return false;
  };

  const showDisbursementButton = () => {
    return (
      loan?.status === retailFilters.PENDING_DISBURSEMENT
      // && user?.uid === loan?.offerer?.uid
    );
  };

  return (
    <Box sx={{ p: 2, position: "sticky", background: "#fff" }}>
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack sx={{ ml: 2 }} justifyContent="center" alignItems="center">
            {kycData?.photoUrl ? (
              <Avatar src={kycData?.photoUrl} sx={{ width: 80, height: 80 }} />
            ) : (
              <Avatar sx={{ width: 100, height: 100 }}>
                <PersonIcon />
              </Avatar>
            )}

            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
              {`${customer?.firstName
                ?.charAt(0)
                .toUpperCase()}${customer?.firstName?.substring(
                1
              )} ${customer?.lastName
                ?.charAt(0)
                .toUpperCase()}${customer?.lastName?.substring(1)}`}
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#616663" }}
            >
              {customer?.email}
            </Typography>
            <Typography sx={{ mt: 1.2 }}>{setStatus()}</Typography>
          </Stack>
        </Box>
      </Stack>
      <Box sx={{ borderBottom: 1, mt: 5, pb: 2, borderBottomColor: "#F0F1ED" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>
            Wallet Account Number
          </Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {customer?.wallet?.accountNumber ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Wallet Balance</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {customer?.wallet?.balance?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) ?? "Not Available"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ borderBottom: 1, mt: 2, pb: 2, borderBottomColor: "#F0F1ED" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Loan Request</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {loan?.loanAmount?.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            }) ?? "Not Available"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Tenure</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>
            {loan?.loanTenure === 1
              ? `${loan?.loanTenure} month`
              : `${loan?.loanTenure} months`}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ my: 2, pb: 2, borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>DTI Calculation</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>Not Available</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Max loan amount</Typography>
          <Typography sx={{ fontWeight: 700, py: 1 }}>Not Available</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={styles.kyc.headerText}>Credit Score</Typography>
          <Typography
            sx={{
              mt: 1,
              backgroundColor: "#19AD421A",
              width: 80,
              textAlign: "center",
              py: 0.5,
              borderRadius: 5,
              fontWeight: 700,
              color: "primary.main",
            }}
          >
            0
          </Typography>
        </Box>
      </Box>

      {shouldSeeRecommendButtons() ? (
        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="contained"
            onClick={() => setOpenRecommendForOffer(true)}
            sx={{ ...styles.bridgeCardTop.success, fontSize: 11 }}
          >
            <Box sx={{ mt: 1, pr: 1 }}>
              <img src={checkIcon} alt="check-icon" />
            </Box>
            Recommend for Offer
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenRecommendForDecline(true)}
            sx={{ ...styles.bridgeCardTop.error, fontSize: 11 }}
          >
            <Box sx={{ mt: 1, pr: 1 }}>
              <img src={closeIcon} alt="close=icon" />
            </Box>
            Recommend for Decline
          </Button>
        </Stack>
      ) : (
        <></>
      )}
      {shouldSeeOfferButtons() ? (
        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="contained"
            onClick={() => setOpenMakeOffer(true)}
            sx={styles.bridgeCardTop.success}
          >
            <Box sx={{ mt: 1, pr: 1 }}>
              <img src={checkIcon} alt="check-icon" />
            </Box>
            Make Offer
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenDecline(true)}
            sx={styles.bridgeCardTop.error}
          >
            <Box sx={{ pr: 2, fontSize: 13.3, mt: 1 }}>
              <img src={closeIcon} alt="close-icon" />
            </Box>
            Decline
          </Button>
        </Stack>
      ) : (
        <></>
      )}
      {showDisbursementButton() ? (
        <Button
          variant="contained"
          onClick={() => setOpenDisburseLoan(true)}
          sx={{ ...styles.bridgeCardTop.success, height: "40px" }}
        >
          Disburse Loan
        </Button>
      ) : (
        <></>
      )}

      <RecommendForOffer
        open={openRecommendForOffer}
        handleClose={() => setOpenRecommendForOffer(false)}
      />
      <RecommendForDecline
        open={openRecommendForDecline}
        handleClose={() => setOpenRecommendForDecline(false)}
      />
      <Decline open={openDecline} handleClose={() => setOpenDecline(false)} />
      <MakeOffer
        open={openMakeOffer}
        handleClose={() => setOpenMakeOffer(false)}
      />
      <DisburseConfirmation
        open={openDisburseLoan}
        handleClose={() => setOpenDisburseLoan(false)}
      />
    </Box>
  );
};
