import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { PieChart } from "@mui/x-charts/PieChart";
import { SectionHeader } from "../../common/components/SectionHeader";
import { styles } from "../../common/css/styles";
import { InfoOutlined } from "@mui/icons-material";
import { CustomersCard } from "./components/CustomersCard";
import { LoanPortfolioMetrics } from "./components/LoanPortfolioMetrics";
import { LoanPortfolioStatistics } from "./components/LoanPortfolioStatistics";
import { LoansPieChart } from "./components/LoansPieChart";
import { CustomersPieChart } from "./components/CustomersPieChart";
import { LoanMetrics } from "./components/LoanMetrics";
import { Pending } from "./components/Pending";
import { DuplicatePie } from "./components/DuplicatePie";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerStats,
  fetchCustomersPie,
  fetchLoanInsights,
  fetchLoanMetrics,
  fetchLoanStats,
  fetchLoansPie,
  fetchPendingCustomers,
  fetchPendingLoans,
  fetchStatistics,
} from "./overviewSlice";
import { overviewFilters } from "./overviewFilters";
import { format } from "date-fns";
import { bridgeFinanceFilters } from "../bridgeFinance/constants/bridgeFinanceFilters";
import { customerFilters } from "../customers/constants/customerFilters";
import { Portfolio } from "./components/Portfolio";
import { overviewType } from "./overviewType";
import { businessFilters } from "../business/constants/businessFilters";
import { retailFilters } from "../loans/constants/retailFilters";
import { loanType } from "../smeLoans/constants/loanType";
import { LoanInsight } from "./components/LoanInsight";
import { Customers } from "./components/Customers";

const loanStatus = [
  { label: "All Loan Status", value: "" },
  // {
  //   label: "Pending Recommendation",
  //   value: retailFilters.PENDING_RECOMMENDATION,
  // },
  // { label: "Pending Offer", value: retailFilters.PENDING_OFFER },
  // { label: "Pending Disbursed", value: retailFilters.PENDING_DISBURSEMENT },
  // { label: "Offered", value: retailFilters.OFFERED },
  // { label: "Rejected Offer", value: retailFilters.OFFER_REJECTED },
  // { label: "Declined Applications", value: retailFilters.DECLINED },
  { label: "Active", value: retailFilters.ACTIVE },
  { label: "Defaulting", value: retailFilters.DEFAULTING },
  { label: "Completed", value: retailFilters.COMPLETED },
  { label: "Lost", value: retailFilters.LOST },
  // { label: "NTU", value: retailFilters.NTUS },
];

const loanTypes = [
  { label: "All Loan Types", value: "" },
  {
    label: "Bridge Financing",
    value: loanType.BRIDGE_FINANCING,
  },
  { label: "Retail Loans", value: loanType.RETAIL },
  { label: "Business Loans", value: loanType.BUSINESS },
  { label: "Asset Financing", value: loanType.ASSET_FINANCING },
];

const ranges = [
  { label: "Last 7 days", value: overviewFilters.LAST7DAYS },
  {
    label: "Last 14 days",
    value: overviewFilters.LAST14DAYS,
  },
  { label: "Last 1 month", value: overviewFilters.ONE_MONTH },
  { label: "Last 2 months", value: overviewFilters.TWO_MONTHS },
  { label: "Last 3 months", value: overviewFilters.THREE_MONTHS },
  { label: "Custom Date", value: overviewFilters.CUSTOM },
];

const getDateRange = (filter) => {
  const d = new Date();
  const range = {
    startDate: "",
    endDate: format(d, "yyyy-MM-dd"),
  };

  switch (filter) {
    case overviewFilters.LAST7DAYS:
      range.startDate = format(d.setDate(d.getDate() - 7), "yyyy-MM-dd");
      break;
    case overviewFilters.LAST14DAYS:
      range.startDate = format(d.setDate(d.getDate() - 14), "yyyy-MM-dd");
      break;
    case overviewFilters.ONE_MONTH:
      range.startDate = format(d.setMonth(d.getMonth() - 1), "yyyy-MM-dd");
      break;
    case overviewFilters.TWO_MONTHS:
      range.startDate = format(d.setMonth(d.getMonth() - 2), "yyyy-MM-dd");
      break;
    case overviewFilters.THREE_MONTHS:
      range.startDate = format(d.setMonth(d.getMonth() - 3), "yyyy-MM-dd");
      break;
    default:
      range.startDate = format(d.setDate(d.getDate() - 7), "yyyy-MM-dd");
      break;
  }
  return range;
};

export const Overview = () => {
  const [pendingDateFilter, setPendingDateFilter] = useState(
    overviewFilters.LAST7DAYS
  );
  const [loansPieDateFilter, setLoansPieDateFilter] = useState(
    overviewFilters.LAST7DAYS
  );
  const [customersPieDateFilter, setCustomersPieDateFilter] = useState(
    overviewFilters.LAST7DAYS
  );
  const [loanMetricsFilter, setLoanMetricsFilter] = useState(
    bridgeFinanceFilters.ALL
  );
  const [loanMetricsDateFilter, setLoanMetricsDateFilter] = useState(
    overviewFilters.LAST7DAYS
  );
  const [loanStatsDateFilter, setLoanStatsDateFilter] = useState(
    overviewFilters.LAST7DAYS
  );
  const [customerStatsDateFilter, setCustomerStatsDateFilter] = useState(
    overviewFilters.LAST7DAYS
  );
  const [pendingFilter, setPendingFilter] = useState(
    bridgeFinanceFilters.PENDING_RECOMMENDATION
  );
  const overview = useSelector((state) => state.overview);
  const dispatch = useDispatch();
  const pieParams = { height: 200, margin: { right: 5 } };

  const handleLoanMetricsDateFilterChange = (event) => {
    setLoanMetricsDateFilter(event.target.value);
  };

  const handleLoanMetricsFilterChange = (filterOption) => {
    setLoanMetricsFilter(filterOption);
    const range = getDateRange(loanMetricsDateFilter);
    dispatch(fetchLoanMetrics({ ...range, status: filterOption }));
  };

  const handlePendingFilter = (filterOption) => {
    setPendingFilter(filterOption);
    const range = getDateRange(pendingDateFilter);
    if (filterOption === customerFilters.PENDING) {
      dispatch(fetchPendingCustomers({ ...range, page: 1 }));
    } else {
      dispatch(
        fetchPendingLoans({
          ...range,
          page: 1,
          filterOption,
        })
      );
    }
  };

  const handlePendingDateFilterChange = (event) => {
    setPendingDateFilter(event.target.value);
  };

  const handleLoansPieDateFilterChange = (event) => {
    setLoansPieDateFilter(event.target.value);
  };
  const handleCustomersPieDateFilterChange = (event) => {
    setCustomersPieDateFilter(event.target.value);
  };

  // useEffect(() => {
  //   const range = getDateRange(filter);
  //   dispatch(fetchStatistics(range));
  // }, [filter]);

  useEffect(() => {
    const range = getDateRange(pendingDateFilter);
    dispatch(
      fetchPendingLoans({
        ...range,
        page: 1,
        filterOption: pendingFilter,
      })
    );
    dispatch(fetchPendingCustomers({ ...range, page: 1 }));
  }, [pendingDateFilter]);

  useEffect(() => {
    const range = getDateRange(loansPieDateFilter);
    dispatch(fetchLoansPie(range));
  }, [loansPieDateFilter]);

  useEffect(() => {
    const range = getDateRange(customersPieDateFilter);
    dispatch(fetchCustomersPie(range));
  }, [customersPieDateFilter]);

  useEffect(() => {
    const range = getDateRange(loanMetricsDateFilter);
    dispatch(
      fetchLoanMetrics({
        ...range,
        type: "ALL",
        status: "ALL",
      })
    );
  }, [loanMetricsDateFilter]);
  // console.log(loanStatsDateFilter);
  useEffect(() => {
    const range = getDateRange(loanStatsDateFilter);
    dispatch(fetchLoanStats({ type: "ALL", ...range }));
  }, [loanStatsDateFilter]);
  useEffect(() => {
    const range = getDateRange(loanStatsDateFilter);
    dispatch(fetchCustomerStats({ ...range }));
  }, [customerStatsDateFilter]);
  useEffect(() => {
    const range = getDateRange(loanStatsDateFilter);
    dispatch(
      fetchLoanInsights({ ...range, type: "ALL", status: "ALL", page: 1 })
    );
  }, [customerStatsDateFilter]);

  // const [currentStage, setCurrentStage] = useState(
  //   overviewType.GENERAL_OVERVIEW
  // );

  const [currentOverviewType, setCurrentOverviewType] = useState(
    overviewType.GENERAL_OVERVIEW
  );
  const handleTabChange = (overviewType) =>
    setCurrentOverviewType(overviewType);
  // console.log(overview);
  // console.log('range', ranges);
  return (
    <>
      <Grid
        container
        sx={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <SectionHeader headerText="Dashboard" />

        {/* <Box
          sx={{
            backgroundColor: "#fff",
            pt: 2,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <FormControl sx={{ mr: 2.5, mt: 1 }}>
              <Select
                value={loanTypeFilter}
                size="small"
                onChange={handleLoanTypeFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={styles.dropdown}
              >
                {loanTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mr: 2.5, mt: 1 }}>
              <Select
                value={loanStatusFilter}
                size="small"
                onChange={handleLoanStatusFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={styles.dropdown}
              >
                {loanStatus.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mr: 3.5, mt: 1 }}>
              <Select
                value={filter}
                size="small"
                onChange={handleStatisticsFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={styles.dropdown}
              >
                {ranges.map((range) => (
                  <MenuItem key={range.value} value={range.value}>
                    {range.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {overview.loadingStatistics ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 180,
              }}
            >
              <CircularProgress sx={{ alignItems: "center" }} />
            </Box>
          ) : (
            <>
              <LoanPortfolioMetrics
                loansMetrics={overview.statistics.loansMetrics}
              />
              <LoanPortfolioStatistics
                loansStatistics={overview.statistics.loansStatistics}
              />
              <CustomersCard
                customersStatistics={overview.statistics.customersStatistics}
              />
            </>
          )}
        </Box> */}

        <LoanPortfolioMetrics
          overview={overview}
          loanStatus={loanStatus}
          loanTypes={loanTypes}
          ranges={ranges}
        />
        <LoanPortfolioStatistics
          overview={overview}
          loanTypes={loanTypes}
          ranges={ranges}
        />
        <Customers
          overview={overview}
          ranges={ranges}
          customersStatistics={overview.customersPie}
        />

        <LoanInsight
          overview={overview}
          loanTypes={loanTypes}
          ranges={ranges}
          loanStatus={loanStatus}
        />
        {/* <LoanMetrics
          overview={overview}
          filterOption={loanMetricsFilter}
          handleLoanMetricsFilterChange={handleLoanMetricsFilterChange}
          loanMetricsDateFilter={loanMetricsDateFilter}
          handleLoanMetricsDateFilterChange={handleLoanMetricsDateFilterChange}
        /> */}
        {/* <Pending
          overview={overview}
          filterOption={pendingFilter}
          handlePendingFilter={handlePendingFilter}
          pendingDateFilter={pendingDateFilter}
          handlePendingDateFilterChange={handlePendingDateFilterChange}
        /> */}
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <LoansPieChart
            loansStatistics={overview.loansPie}
            loansPieDateFilter={loansPieDateFilter}
            handleLoansPieDateFilterChange={handleLoansPieDateFilterChange}
          />
          <CustomersPieChart
            customersStatistics={overview.customersPie}
            customersPieDateFilter={customersPieDateFilter}
            handleCustomersPieDateFilterChange={
              handleCustomersPieDateFilterChange
            }
          />
        </Box> */}
        {/* <DuplicatePie /> */}
      </Grid>
    </>
  );
};
