import { clearSession, setError } from "../../features/auth/authSlice";

export const toTitleCase = (str) => {
  return str?.replace(
    /\w*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const handleError = async (error, dispatch, rejectWithValue) => {
  if (error.code === "ERR_NETWORK") {
    // Handle network connection error and dispatch an action
    return rejectWithValue("Network connection error");
  }

  const message = error.response?.data?.message.join().toLowerCase();
  if (
    error.response.config.url !== "login" &&
    error.response.config.url !== "auth/refresh" &&
    message.includes("token")
  ) {
    clearSession();
    dispatch(
      setError([
        "Sorry, your session has expired. Kindly login again to continue",
      ])
    );
    return rejectWithValue(
      "Sorry, your session has expired. Kindly login again to continue"
    );
  } else if (message.includes("java")) {
    return rejectWithValue("Oops! An error occured. Please contact support");
  } else {
    // Handle other errors
    return rejectWithValue(
      error.response.data
        ? error.response.data.message
        : "Oops! An error occured. Please contact support"
    ); // This will propagate other errors to the rejected state
  }
};
