import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { toTitleCase } from "../../../common/constants/utility";

export const RecommendationInfo = ({ loan }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          pt: 2,
        }}
      >
        {/* <Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Typography
              sx={{ fontWeight: 800, color: "#62635E", fontSize: 18 }}
            >
              System Recommendation:
            </Typography>
            <Typography
              sx={{ fontWeight: 700, color: "#D41818", fontSize: 18 }}
            >
              DECLINE
            </Typography>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
              Reason for recomendation for Offer
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>

            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                mb: 2,
                pb: 2,
                borderBottom: 1,
                borderBottomColor: "#F0F1ED",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis
            </Typography>
          </Box>
        </Box> */}

        {loan?.offerer ? (
          <Box>
            <Typography
              sx={{ fontWeight: 800, color: "#62635E", fontSize: 18 }}
            >
              {`Recommendation for ${
                loan?.recommendOffer ? "offer" : "decline"
              } by ${toTitleCase(loan?.offerer?.firstName)} ${toTitleCase(
                loan?.offerer?.lastName
              )}`}
            </Typography>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
            >
              <Box>
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Offered loan amount
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                  {loan.loanOfferAmount?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }) ?? "Not Available"}
                </Typography>
              </Box>

              <Box>
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Preset interest rate
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                  {loan.loanOfferInterestRate}%
                </Typography>
              </Box>

              <Box>
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Tenure
                </Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 700, py: 1 }}>
                  {loan.loanOfferTenure === 1
                    ? `${loan?.loanOfferTenure} Month`
                    : `${loan?.loanOfferTenure} Months`}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 1 }}>
                {`Reason for recommendation for ${
                  loan?.recommendOffer ? "offer" : "decline"
                }`}
              </Typography>
              {loan?.reasonsForRecommendation?.map((reason) => (
                <Typography
                  sx={{
                    py: 2,
                    borderBottom: 1,
                    borderBottomColor: "#F0F1ED",
                    fontSize: 14,
                    fontWeight: 400,
                    // textTransform: "capitalize",
                  }}
                >
                  {`${reason.charAt(0).toUpperCase()}${reason.substring(1)}`}
                  {/* {reason} */}
                </Typography>
              ))}
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Not Available
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
