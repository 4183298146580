import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { TopBar } from "../components/TopBar";
import { MainAppBar } from "../components/AppBar";
import { Sidebar } from "../components/Sidebar";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken, setUser } from "../../features/auth/authSlice";
import { feedback } from "../constants/feedback";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getMenuItems } from "../constants/menu";

export const PrivateLayout = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = auth.isAuthenticated;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const refresh_token = userData?.auth?.refresh_token;
  const menuItems = getMenuItems(userData?.user?.roles[0].name);
  const shouldBeVisible = menuItems.find((menu) =>
    location.pathname.startsWith(menu.path)
  )?.visible;

  useEffect(() => {
    const timerId = setInterval(() => {
      dispatch(refreshToken(refresh_token));
    }, 780000);

    return () => {
      clearInterval(timerId);
    };
  }, [auth.access_token]);

  useEffect(() => {
    // page refreshed
    if (isAuthenticated !== feedback.SUCCESSFUL && !!userData) {
      dispatch(setUser(userData));
      navigate(location.pathname);
    }
  }, []);

  return (
    <>
      {isAuthenticated === feedback.SUCCESSFUL && shouldBeVisible ? (
        <Grid
          md={12}
          xs={12}
          sx={{
            backgroundColor: "#F5FAF7",
            display: { md: "block", xs: "block", sm: "block" },
            minHeight: "100vh",
          }}
        >
          <Grid container sx={{ display: "flex" }}>
            <Grid
              md={2}
              container
              xs={false}
              sx={{
                height: "100vh",
                // backgroundColor: "#fff",
                display: { md: "block", xs: "none", sm: "none" },
              }}
            ></Grid>

            {/* Main body */}
            <Grid
              item
              md={10}
              container
              sx={{
                display: { md: "block" },
              }}
            >
              <Box md={12} sx={{ height: "8vh" }}></Box>
              <Grid container sx={{ pt: 5, pb: 2, px: 2 }}>
                <Outlet />
              </Grid>
            </Grid>
          </Grid>
          <TopBar />
          <Sidebar />
          <MainAppBar />
        </Grid>
      ) : isAuthenticated === feedback.SUCCESSFUL && !shouldBeVisible ? (
        <Navigate to="/404" state={{ toUnauthorized: true }} />
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
