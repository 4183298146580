import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../common/css/styles";
import { format, intervalToDuration } from "date-fns";
import { useNavigate } from "react-router-dom";
import { retailFilters } from "./constants/retailFilters";
import { DeadState } from "../../common/components/DeadState";
import { PageNavigation } from "../../common/components/PageNavigation";
import { useDispatch, useSelector } from "react-redux";
import { fetchRetailLoan } from "./store/retailLoanSlice";
import { ProceedToRecommend } from "./components/ProceedToRecommend";
import { ProceedToOffer } from "./components/ProceedToOffer";
import { PreventOfferAttempt } from "./components/PreventOfferAttempt";
import { DenyLoanView } from "./components/DenyLoanView";
import { loanType } from "../smeLoans/constants/loanType";
import { fetchBridgeLoan } from "../bridgeFinance/store/bridgeFinanceLoanSlice";
import { bridgeFinanceFilters } from "../bridgeFinance/constants/bridgeFinanceFilters";

const getStatusDisplay = (status) => {
  switch (status) {
    case retailFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.bridgeTable.status.pendingVerification}>
          Pending Recommendation
        </Typography>
      );
    case retailFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.bridgeTable.status.pendingOffer}>
          Pending Offer
        </Typography>
      );
    case retailFilters.PENDING_APPROVAL:
      return (
        <Typography sx={styles.bridgeTable.status.pendingApproval}>
          Pending Approval
        </Typography>
      );
    case retailFilters.OFFERED:
      return (
        <Typography sx={styles.bridgeTable.status.offered}>Offered</Typography>
      );
    case retailFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.bridgeTable.status.rejected}>
          Offer Rejected
        </Typography>
      );
    case retailFilters.LOAN_REJECTED:
      return (
        <Typography sx={styles.bridgeTable.status.rejected}>
          Loan Rejected
        </Typography>
      );
    case retailFilters.OFFER_ACCEPTED:
      return (
        <Typography sx={styles.bridgeTable.status.accepted}>
          Offer Accepted
        </Typography>
      );
    case retailFilters.DEFAULTING:
      return (
        <Typography sx={styles.bridgeTable.status.defaulting}>
          Defaulting
        </Typography>
      );
    case retailFilters.REQUEST_DECLINED:
      return (
        <Typography sx={styles.bridgeTable.status.declined}>
          Request Declined
        </Typography>
      );
    case retailFilters.ACTIVE:
      return (
        <Typography sx={styles.bridgeTable.status.active}>Active</Typography>
      );
    case retailFilters.COMPLETED:
      return (
        <Typography sx={styles.bridgeTable.status.completed}>
          Completed
        </Typography>
      );
    case retailFilters.NTU:
      return <Typography sx={styles.bridgeTable.status.ntu}>NTU</Typography>;
    case retailFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.bridgeTable.status.pendingDisbursement}>
          Pending Disbursement
        </Typography>
      );
    default:
      break;
  }
};

export const UpdatedLoansTable = ({
  loans,
  loanTypeFilter,
  loanStatus,
  page,
  handlePageChange,
  // handleView,
}) => {
  const [openProceedToRecommend, setOpenProceedToRecommend] = useState(false);
  const [openProceedToOffer, setOpenProceedToOffer] = useState(false);
  const [openPreventOfferAttempt, setOpenPreventOfferAttempt] = useState(false);
  const [openDenyLoanView, setOpenDenyLoanView] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listOfLoans = loans.loans;
  const user = useSelector(({ auth }) => auth.user);

  const columns = [
    { id: "uid", label: "Loan UID", minWidth: 50, visible: true },
    {
      id: "status",
      label: "Status",
      minWidth: 50,
      visible:
        loanStatus === retailFilters.ALL ||
        loanStatus === retailFilters.VERIFIED
          ? true
          : false,
    },

    {
      id: "loanType",
      label: "Loan Type",
      minWidth: 20,
      visible: loanTypeFilter === "" ? true : false,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 20,
      visible:
        loanStatus === retailFilters.ALL ||
        loanStatus === retailFilters.ACTIVE ||
        loanStatus === retailFilters.DEFAULTING ||
        loanStatus === retailFilters.COMPLETED
          ? false
          : true,
    },
    {
      id: "requestedAmount",
      label: "Requested Amount (₦)",
      minWidth: 50,
      visible:
        loanStatus === retailFilters.ALL ||
        loanStatus === retailFilters.ACTIVE ||
        loanStatus === retailFilters.DEFAULTING ||
        loanStatus === retailFilters.COMPLETED
          ? false
          : true,
    },
    {
      id: "amountDisbursed",
      label: "Amount Disbursed (₦)",
      minWidth: 50,
      visible: true,
    },
    {
      id: "paid",
      label: "Paid (₦)",
      minWidth: 50,
      visible: true,
    },
    {
      id: "outstanding",
      label: "Outstanding (₦)",
      minWidth: 50,
      visible: true,
    },

    {
      id: "repaymentDate",
      label: "Next Repayment Date",
      minWidth: 50,
      visible: true,
    },
    {
      id: "daysDefaulting",
      label: "Days Defaulting",
      minWidth: 50,
      visible: true,
    },
    {
      id: "interestReceived",
      label: "Interest Received (₦)",
      minWidth: 50,
      visible: true,
    },
    {
      id: "recommender",
      label: "Recommender",
      minWidth: 20,
      visible:
        loanStatus === retailFilters.ALL ||
        loanStatus === retailFilters.ACTIVE ||
        loanStatus === retailFilters.DEFAULTING ||
        loanStatus === retailFilters.COMPLETED
          ? false
          : true,
    },
    {
      id: "offerer",
      label: "Offeror",
      minWidth: 20,
      visible:
        loanStatus === retailFilters.ALL ||
        loanStatus === retailFilters.ACTIVE ||
        loanStatus === retailFilters.DEFAULTING ||
        loanStatus === retailFilters.COMPLETED
          ? false
          : true,
    },

    {
      id: "view",
      label: "View",
      minWidth: 50,
      visible: true,
      // filterOption === loansFilters.ALL ||
      // filterOption === loansFilters.PROSPECT
      //   ? false
      //   : true,
    },
  ];

  const handleBridgeFinanceView = (uid) => {
    dispatch(fetchBridgeLoan(uid));

    const loan = listOfLoans.find((loan) => loan.uid === uid);

    let assigned = false;
    if (loan?.status === bridgeFinanceFilters.PENDING_RECOMMENDATION) {
      assigned = !!loan.recommender;
      if (!assigned) setOpenProceedToRecommend(true);
      else
        navigate(`/bridge-finance/kyc/${loan.uid}`, {
          state: { loanStatus: loanStatus },
        });
    } else if (loan?.status === bridgeFinanceFilters.PENDING_OFFER) {
      assigned = !!loan.offerer;
      if (!assigned && loan.recommender?.uid === user.uid)
        setOpenPreventOfferAttempt(true);
      else if (!assigned && loan.recommender?.uid !== user.uid)
        setOpenProceedToOffer(true);
      else
        navigate(`/bridge-finance/kyc/${loan.uid}`, {
          state: { loanStatus: loanStatus },
        });
    } else {
      navigate(`/bridge-finance/kyc/${loan.uid}`, {
        state: { loanStatus: loanStatus },
      });
    }
  };

  const handleRetailView = (uid) => {
    dispatch(fetchRetailLoan(uid));

    const loan = listOfLoans.find((loan) => loan.uid === uid);

    let assigned = false;
    if (loan?.status === retailFilters.PENDING_RECOMMENDATION) {
      assigned = !!loan.recommender;
      if (!assigned) setOpenProceedToRecommend(true);
      else
        navigate(`/loans/overview/${loan.uid}`, {
          state: { loanStatus: loanStatus },
        });
    } else if (loan?.status === retailFilters.PENDING_OFFER) {
      assigned = !!loan.offerer;
      if (!assigned && loan.recommender?.uid === user.uid)
        setOpenPreventOfferAttempt(true);
      else if (!assigned && loan.recommender?.uid !== user.uid)
        setOpenProceedToOffer(true);
      else
        navigate(`/loans/overview/${loan.uid}`, {
          state: { loanStatus: loanStatus },
        });
    } else {
      navigate(`/loans/overview/${loan.uid}`, {
        state: { loanStatus: loanStatus },
      });
    }
  };

  const handleView = (uid) => {
    switch (loanTypeFilter) {
      case loanType.BRIDGE_FINANCING:
        handleBridgeFinanceView(uid);
        break;
      case loanType.RETAIL:
        handleRetailView(uid);
        break;
      case loanType.BUSINESS:
        navigate(`/sme/loans/overview/${uid}`, {
          state: { loanStatus: loanStatus },
        });
        break;
      case loanType.ASSET_FINANCING:
        navigate(`/sme/asset-financing/overview/${uid}`, {
          state: { loanStatus: loanStatus },
        });
        break;
      default:
        break;
    }
    // dispatch(fetchRetailLoan(uid));

    // const loan = listOfLoans.find((loan) => loan.uid === uid);
    // console.log(loan);
    // let assigned = false;
    // if (loan?.type === "RETAIL" && loan?.status !== "ACTIVE") {
    //   if (loan?.status === retailFilters.PENDING_RECOMMENDATION) {
    //     assigned = !!loan.recommender;
    //     if (!assigned) setOpenProceedToRecommend(true);
    //     else navigate(`/new-loans/overview/${loan.uid}`);
    //   } else if (loan?.status === retailFilters.PENDING_OFFER) {
    //     assigned = !!loan.offerer;
    //     if (!assigned && loan.recommender?.uid === user.uid)
    //       setOpenPreventOfferAttempt(true);
    //     else if (!assigned && loan.recommender?.uid !== user.uid)
    //       setOpenProceedToOffer(true);
    //     else navigate(`/new-loans/overview/${loan.uid}`);
    //   } else {
    //     navigate(`/new-loans/overview/${loan.uid}`);
    //   }
    // } else if (loan?.type === "RETAIL") {
    //   navigate(`/new-loans/details/${loan.uid}`);
    // }
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                ?.filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loans.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : listOfLoans?.length > 0 ? (
              listOfLoans?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={
                    loanTypeFilter === loanType.BRIDGE_FINANCING ||
                    loanTypeFilter === loanType.RETAIL
                      ? loan.uid
                      : loan.loanUid
                  }
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text.uid}>
                      {loanTypeFilter === loanType.BRIDGE_FINANCING ||
                      loanTypeFilter === loanType.RETAIL
                        ? loan.uid
                        : loan.loanUid}
                    </Typography>
                  </TableCell>
                  {columns?.find((column) => column.id === "status")
                    ?.visible ? (
                    <TableCell align="left">
                      {getStatusDisplay(loan.status)}
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "loanType")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {loan?.loanType}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "email")?.visible ? (
                    <TableCell align="left">
                      <Typography
                        sx={{
                          ...styles.table.text,
                          textTransform: "lowercase",
                        }}
                      >
                        {loan.user?.email}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {/* Requested Amount */}
                  {columns?.find((column) => column.id === "requestedAmount")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {parseFloat(loan?.loanAmount).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {/* Disbursed Amount */}
                  {columns?.find((column) => column.id === "amountDisbursed")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {parseFloat(loan?.loanOfferAmount ?? 0).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {/* Paid Amount */}
                  {columns?.find((column) => column.id === "paid")?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {parseFloat(
                          loan?.totalCollectedAmount ?? 0
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {/* Outstanding Amount */}
                  {columns?.find((column) => column.id === "outstanding")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {parseFloat(
                          loan?.loanOfferAmount > loan?.totalCollectedAmount
                            ? loan?.loanOfferAmount - loan?.totalCollectedAmount
                            : 0
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {/* Days Defaulting */}
                  {columns?.find((column) => column.id === "repaymentDate")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {loan?.nextRepaymentDate &&
                          format(
                            new Date(loan?.nextRepaymentDate),
                            "dd/MM/yyyy"
                          )}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {/* Next Repayment Date */}
                  {columns?.find((column) => column.id === "daysDefaulting")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {loan?.defaultingDate
                          ? intervalToDuration({
                              start: new Date(loan?.defaultingDate),
                              end: new Date(),
                            }).days
                          : 0}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {/* Interest Received */}
                  {columns?.find((column) => column.id === "interestReceived")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {parseFloat(
                          loan?.loanOfferTotalInterest ?? 0
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {columns?.find((column) => column.id === "recommender")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {loan.recommender
                          ? `${loan.recommender?.firstName} ${loan.recommender?.lastName}`
                          : "Unassigned"}
                      </Typography>
                    </TableCell>
                  ) : null}

                  {columns?.find((column) => column.id === "offerer")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {loan.offerer
                          ? `${loan.offerer?.firstName} ${loan.offerer?.lastName}`
                          : "Unassigned"}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "view")?.visible ? (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleView(
                            loanTypeFilter === loanType.BRIDGE_FINANCING ||
                              loanTypeFilter === loanType.RETAIL
                              ? loan.uid
                              : loan.loanUid
                          )
                        }
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns?.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!loans.loading && listOfLoans?.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={loans.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
      <ProceedToRecommend
        open={openProceedToRecommend}
        handleClose={() => setOpenProceedToRecommend(false)}
      />
      <ProceedToOffer
        open={openProceedToOffer}
        handleClose={() => setOpenProceedToOffer(false)}
      />

      {/* <ProceedToAction
        open={openProceedToAction}
        handleClose={() => setOpenProceedToAction(false)}
        role={role}
      /> */}
      <PreventOfferAttempt
        open={openPreventOfferAttempt}
        handleClose={() => setOpenPreventOfferAttempt(false)}
      />
      <DenyLoanView
        open={openDenyLoanView}
        handleClose={() => setOpenDenyLoanView(false)}
      />
    </Box>
  );
};
