import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { bridgeFinanceFilters } from "../../bridgeFinance/constants/bridgeFinanceFilters";
import { roles } from "../../../common/constants/roles";
import { useDispatch, useSelector } from "react-redux";
import { filteredBridgeLoans } from "../../bridgeFinance/store/bridgeFinanceLoansSlice";
import { styles } from "../../../common/css/styles";
import { BridgeTable } from "../../bridgeFinance/BridgeTable";
import { PendingTable } from "./PendingTable";
import { customerFilters } from "../../customers/constants/customerFilters";
import { fetchPendingLoans } from "../overviewSlice";
import { overviewFilters } from "../overviewFilters";
import { useNavigate } from "react-router-dom";
import { LoansTable } from "./LoansTable";
import { CollectionsTable } from "./CollectionsTable";
import { useLoanParams } from "../hooks/useLoanParams";
import { format } from "date-fns";
import CustomDate from "../../../common/components/CustomDate";
import dayjs from "dayjs";
import { Download } from "@mui/icons-material";
import { exportToExcel } from "react-json-to-excel";

const filters = [
  {
    label: "Loans",
    value: "LOANS",
    visible: true,
  },
  {
    label: "Collections",
    value: "COLLECTIONS",
    visible: true,
  },
];
const d = new Date();
export const LoanInsight = ({
  overview,
  loanTypes,
  ranges,
  pendingDateFilter,
  handlePendingDateFilterChange,
  loanStatus,
}) => {
  const { getDateRange, fetchLoanInsights, dispatch } = useLoanParams();
  const [filterOption, setFilterOption] = useState("LOANS");
  const [filter, setFilter] = useState(overviewFilters.LAST7DAYS);
  const [loanTypeFilter, setLoanTypeFilter] = useState("");
  const [loanStatusFilter, setLoanStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [customFilter, setCustomFilter] = useState({
    open: false,
    startDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
    endDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
  });
  const today = format(d.setDate(d.getDate()), "yyyy-MM-dd");
  const navigate = useNavigate();

  const handleLoanTypeFilterChange = (event) => {
    setLoanTypeFilter(event.target.value);
    setPage(1);
    const range = filter === "CUSTOM" ? customFilter : getDateRange(filter);
    dispatch(
      fetchLoanInsights({
        ...range,
        type: event.target.value === "" ? "ALL" : event.target.value,
        status: loanStatusFilter === "" ? "ALL" : loanStatusFilter,
        page: page,
      })
    );
  };

  const handleStatisticsFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
    const range =
      filter === "CUSTOM" ? customFilter : getDateRange(event.target.value);
    if (event.target.value !== "CUSTOM") {
      dispatch(
        fetchLoanInsights({
          ...range,
          type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
          status: loanStatusFilter === "" ? "ALL" : loanStatusFilter,
          page: page,
        })
      );
    } else {
      setCustomFilter({
        startDate: range.startDate,
        endDate: range.endDate,
        open: true,
      });
    }
  };

  const handleLoanStatusFilterChange = (event) => {
    setLoanStatusFilter(event.target.value);
    setPage(1);
    const range = filter === "CUSTOM" ? customFilter : getDateRange(filter);
    dispatch(
      fetchLoanInsights({
        ...range,
        type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
        status: event.target.value === "" ? "ALL" : event.target.value,
        page: page,
      })
    );
  };

  const handlePageChange = (event, newPage) => {
    const range = filter === "CUSTOM" ? customFilter : getDateRange(filter);
    setPage(newPage);
    dispatch(
      fetchLoanInsights({
        ...range,
        type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
        status: loanStatusFilter === "" ? "ALL" : loanStatusFilter,
        page: page,
      })
    );
  };

  const handleViewMore = () => {
    navigate("/bridge-finance");
  };

  const handleCustomFilter = (name, value) => {
    // console.log("custom filter", dayjs(value.$d).format("YYYY-MM-DD"));
    setCustomFilter({
      ...customFilter,
      [name]: dayjs(value.$d).format("YYYY-MM-DD"),
    });
  };
  const applyCustomFilter = () => {
    setPage(1);
    setCustomFilter({ ...customFilter, open: false });
    dispatch(
      // fetchCustomerStats({
      //   startDate: customFilter.startDate,
      //   endDate: customFilter.endDate,
      // })
      fetchLoanInsights({
        startDate: customFilter.startDate,
        endDate: customFilter.endDate,
        type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
        status: loanStatusFilter === "" ? "ALL" : loanStatusFilter,
        page: page,
      })
    );
  };
  const calculateDate = (date, type) => {
    // const formatted = format(date, "yyyy-MM-dd");
    const val =
      type === "backward"
        ? dayjs(today).diff(date.slice(0, 10), "day", true)
        : dayjs(date.slice(0, 10)).diff(today, "day", true);
    return val < 1 ? 0 : val;
  };
  const csvData = overview.loanInsights.loans.reduce((res, item) => {
    res.push({
      uid: item.uid,
      status: item.status,
      type: item.type,
      "Amount Paid": item.amountPaid,
      "Amount Outstanding": item.amountOutstanding,
      "Customer Name": item.customerName,
      "Customer Email": item.customerEmail,
      "Customer Phone": item.customerPhone,
      "Interest Rate": item.interestRate,
      "Interest Amount": item.interestAmount,
      "Next Repayment Count down": item?.nextRepaymentDate
        ? calculateDate(item?.nextRepaymentDate, "forward")
        : "N/A",
      "Days Outstanding": item?.defaultingDate
        ? calculateDate(item?.defaultingDate, "backward")
        : "N/A",
      Recommender: item.offerer
        ? item.recommender.firstName + " " + item.recommender.lastName
        : "N/A",
      Offerer: item.offerer
        ? item.offerer.firstName + " " + item.offerer.lastName
        : "N/A",
    });
    return res;
  }, []);

  return (
    <Box
      item
      sx={{
        width: "100%",
        // height: 180,
        backgroundColor: "#fff",
        borderRadius: 2,
        mt: 2,
      }}
    >
      <Box
        sx={{
          p: 2,
          px: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
            Loan Insight
          </Typography>
          <Box
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => exportToExcel(csvData, "LoanInsight")}
              sx={{
                color: "#232900",
                ml: 3,
                textTransform: "capitalize",
                height: 30,
                width: 130,
                fontSize: 11,
                boxShadow: "none",
                border: "1px solid #B5B5B5",
              }}
            >
              Export CSV <img alt="" />
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              <FormControl sx={{ mr: 2.5, mt: 1 }}>
                <Select
                  value={loanTypeFilter}
                  size="small"
                  onChange={handleLoanTypeFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {loanTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ mr: 2.5, mt: 1 }}>
                <Select
                  value={loanStatusFilter}
                  size="small"
                  onChange={handleLoanStatusFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {loanStatus.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ mt: 1 }}>
                <Select
                  value={filter}
                  size="small"
                  onChange={handleStatisticsFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {ranges.map((range) => (
                    <MenuItem
                      onClick={() =>
                        range.value === "CUSTOM"
                          ? setCustomFilter({ ...customFilter, open: true })
                          : null
                      }
                      key={range.value}
                      value={range.value}
                    >
                      {range.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {customFilter.open ? (
                <CustomDate
                  handleCustomFilter={handleCustomFilter}
                  customFilter={customFilter}
                  close={() =>
                    setCustomFilter({ ...customFilter, open: false })
                  }
                  applyCustomFilter={applyCustomFilter}
                />
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
        {/* <Box sx={{ py: 2 }}>
          {filters
            .filter((filter) => filter.visible === true)
            .map((filter) => (
              <Button
                key={filter.value}
                onClick={() => handlePendingFilter(filter.value)}
                variant="outlined"
                sx={
                  filterOption === filter.value
                    ? styles.tableFilter.active
                    : styles.tableFilter.inactive
                }
              >
                {filter.label}
              </Button>
            ))}
        </Box> */}
        {filterOption === "LOANS" ? (
          <LoansTable
            overview={overview}
            handlePageChange={handlePageChange}
            page={page - 1}
            filter={loanStatusFilter}
          />
        ) : (
          <CollectionsTable overview={overview} />
        )}
      </Box>
      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", mx: 2 }}>
        <Button sx={{ textTransform: "capitalize" }} onClick={handleViewMore}>
          View More
        </Button>
      </Box> */}
    </Box>
  );
};
