import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../../../common/constants/utility";
import { search } from "../../../../common/api/customers";

const initialState = {
  loans: [],
  totalRecords: 0,
  loading: false,
  error: [],
};

export const filteredLoans = createAsyncThunk(
  "loans/filterBy",
  async ({ page, filterOption }, { dispatch, rejectWithValue }) => {
    // try {
    //   const response = await getLoans(page, filterOption);
    //   return await response.data;
    // } catch (err) {
    //   return handleError(err, dispatch, rejectWithValue);
    // }
  }
);

export const searchLoans = createAsyncThunk(
  "loans/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await search(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const loansSlice = createSlice({
  name: "loans",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(filteredLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(filteredLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(filteredLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchLoans.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchLoans.fulfilled, (state, action) => {
      state.loans = action.payload.data.loans;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(searchLoans.rejected, (state, action) => {
      state.loans = [];
      state.totalRecords = 0;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default loansSlice.reducer;
