import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import doneIcon from "../../../common/images/done-icon.svg";

export const DisburseSuccess = ({ open, handleClose }) => {
  const loan = useSelector(({ retailLoan }) => retailLoan.loan);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   minWidth: "500px",
        }}
      >
        <DialogContent>
          <Box
            sx={{
              width: "280px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box>
              <img src={doneIcon} width="96px" alt="done-icon" />
            </Box>
          </Box>

          <DialogContentText sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: 17, fontWeight: 500 }}>
              Disburse Loan
            </Typography>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "#969997" }}
            >
              {loan?.loanOfferAmount?.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
              })}{" "}
              has been disbursed successfully
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
