import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBanks,
  getCustomer,
  getWallets,
  postCustomer,
  updatePersonalInfo,
  verificationDecision,
} from "../../../../common/api/customers";
import { loansFilters } from "../constants/loansFilters";
import { feedback } from "../../../../common/constants/feedback";
import { handleError } from "../../../../common/constants/utility";

const initialState = {
  customer: null,
  loading: false,
  error: [],
  feedback: "",
};

export const fetchCustomer = createAsyncThunk(
  "customer/fetch",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCustomer(uid);
      dispatch(fetchCustomerBanks(uid));
      dispatch(fetchCustomerWallets(uid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const verifyCustomer = createAsyncThunk(
  "customer/verify",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await verificationDecision(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const updatePersonal = createAsyncThunk(
  "user/updatePersonal",
  async (model, { dispatch, rejectWithValue }) => {
    const updatedInfo = {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      phoneNumber: model.phoneNumber,
      dateOfBirth: model.dateOfBirth,
    };
    try {
      const response = await updatePersonalInfo(model);
      dispatch(updateCustomer(updatedInfo));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchCustomerBanks = createAsyncThunk(
  "customer/fetch-banks",
  async (customerUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getBanks(customerUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const addCustomer = createAsyncThunk(
  "customer/add",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await postCustomer(model);
      dispatch(fetchCustomer(response.data.data.uid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchCustomerWallets = createAsyncThunk(
  "customer/fetch-wallets",
  async (customerUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getWallets(customerUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateCustomer: (state, action) => {
      state.customer = { ...state.customer, ...action.payload };
      state.error = [];
    },
    clearCustomer: (state) => {
      state.customer = null;
      state.error = [];
    },
    clearCustomerFeedback: (state, action) => {
      state.feedback = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyCustomer.fulfilled, (state, action) => {
      state.customer = {
        ...state.customer,
        idVerified: action.payload.status,
        status: loansFilters.VERIFIED,
      };
      state.error = [];
    });
    builder.addCase(fetchCustomer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomer.fulfilled, (state, action) => {
      state.customer = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchCustomer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updatePersonal.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(updatePersonal.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchCustomerBanks.fulfilled, (state, action) => {
      state.customer = { ...state.customer, banks: action.payload.data.banks };
    });
    builder.addCase(fetchCustomerBanks.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(addCustomer.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      state.feedback = feedback.SUCCESSFUL;

      state.error = [];
    });
    builder.addCase(addCustomer.rejected, (state, action) => {
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(fetchCustomerWallets.fulfilled, (state, action) => {
      state.customer = {
        ...state.customer,
        wallet: action.payload.data,
      };
    });
    builder.addCase(fetchCustomerWallets.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { updateCustomer, clearCustomer, clearCustomerFeedback } =
  customerSlice.actions;
export default customerSlice.reducer;
