import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import avatarDummy from "../../../common/images/avatar-image.jpg";
import { CheckOutlined, CheckroomOutlined, Edit } from "@mui/icons-material";
import { styles } from "../../../common/css/styles";
import checkIcon from "../../../common/images/check-icon.svg";
import closeIcon from "../../../common/images/close-1con.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchInterestRates } from "../../settings/settingsSlice";

export const RecommendationInfo = ({ loan }) => {
  const interestRates = useSelector(({ settings }) => settings.interestRates);
  const dispatch = useDispatch();

  const getPresetInterestRate = (loanAmount) => {
    const range = interestRates.find(
      (x) => x.lowerBound <= loanAmount && x.upperBound >= loanAmount
    );
    if (range) return `${range.interestRate}%`;
    else return "Not Set";
  };

  useEffect(() => {
    if (interestRates.length === 0) {
      dispatch(fetchInterestRates());
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "70%" }}>
      {/* Top */}

      <Typography sx={{ fontWeight: 700, fontSize: 18, mt: 2 }}>
        {`Recommendation for ${loan?.recommendOffer ? "Offer" : "Decline"}`}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* Recommendation Info */}
        <Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Requested loan amount
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {loan?.loanAmount?.toLocaleString("en-NG", {
                style: "currency",
                currency: "NGN",
              })}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Preset interest rate
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {getPresetInterestRate(loan?.loanAmount)}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Requested Tenure
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {loan?.loanTenure} Month(s)
            </Typography>
          </Box>
        </Box>

        {/* Right Side */}
        {loan?.recommendOffer && (
          <Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                Recommended offer amount
              </Typography>
              <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                {loan?.recommendedAmount?.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                })}
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                Recommended interest rate
              </Typography>
              <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                {loan?.recommendedInterestRate}%
              </Typography>
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
                Recommended Tenure
              </Typography>
              <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                {loan?.recommendedTenure} Month(s)
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 2, py: 2 }}>
        <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
          {`Reason for recommendation for ${
            loan?.recommendOffer ? "Offer" : "Decline"
          }`}
        </Typography>
        {loan?.reasonsForRecommendation.map((reason) => (
          <Typography
            sx={{
              py: 2,
              borderBottom: 1,
              borderBottomColor: "#F0F1ED",
              fontSize: 14,
              fontWeight: 400,
              // textTransform: "capitalize",
            }}
          >
            {`${reason.charAt(0).toUpperCase()}${reason.substring(1)}`}
            {/* {reason} */}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
