import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../common/css/styles";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { retailFilters } from "./constants/retailFilters";
import { DeadState } from "../../common/components/DeadState";
import { PageNavigation } from "../../common/components/PageNavigation";
import { useDispatch, useSelector } from "react-redux";
import { fetchRetailLoan } from "./store/retailLoanSlice";
import { ProceedToRecommend } from "./components/ProceedToRecommend";
import { ProceedToOffer } from "./components/ProceedToOffer";
import { PreventOfferAttempt } from "./components/PreventOfferAttempt";
import { DenyLoanView } from "./components/DenyLoanView";

const getStatusDisplay = (status) => {
  switch (status) {
    case retailFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.bridgeTable.status.pendingVerification}>
          Pending Recommendation
        </Typography>
      );
    case retailFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.bridgeTable.status.pendingOffer}>
          Pending Offer
        </Typography>
      );
    case retailFilters.OFFERED:
      return (
        <Typography sx={styles.bridgeTable.status.offered}>Offered</Typography>
      );
    case retailFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.bridgeTable.status.rejected}>
          Offer Rejected
        </Typography>
      );
    case retailFilters.DEFAULTING:
      return (
        <Typography sx={styles.bridgeTable.status.defaulting}>
          Defaulting
        </Typography>
      );
    case retailFilters.DECLINED:
      return (
        <Typography sx={styles.bridgeTable.status.declined}>
          Declined
        </Typography>
      );
    case retailFilters.ACTIVE:
      return (
        <Typography sx={styles.bridgeTable.status.active}>Active</Typography>
      );
    case retailFilters.COMPLETED:
      return (
        <Typography sx={styles.bridgeTable.status.completed}>
          Completed
        </Typography>
      );
    case retailFilters.NTUS:
      return <Typography sx={styles.bridgeTable.status.ntu}>NTU</Typography>;
    case retailFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.bridgeTable.status.pendingDisbursement}>
          Pending Disbursement
        </Typography>
      );
    default:
      break;
  }
};

export const LoansTable = ({
  retailLoans,
  filterOption,
  page,
  handlePageChange,
  // handleView,
}) => {
  const [openProceedToRecommend, setOpenProceedToRecommend] = useState(false);
  const [openProceedToOffer, setOpenProceedToOffer] = useState(false);
  const [openPreventOfferAttempt, setOpenPreventOfferAttempt] = useState(false);
  const [openDenyLoanView, setOpenDenyLoanView] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loans = retailLoans.loans;
  const user = useSelector(({ auth }) => auth.user);

  const columns = [
    { id: "uid", label: "UID", minWidth: 50, visible: true },
    { id: "recommender", label: "Recommender", minWidth: 20, visible: true },
    { id: "offerer", label: "Offerer", minWidth: 20, visible: true },
    { id: "firstName", label: "First Name", minWidth: 50, visible: true },
    { id: "lastName", label: "Last Name", minWidth: 50, visible: true },
    { id: "amount", label: "Amount (₦)", minWidth: 50, visible: true },
    { id: "tenure", label: "Tenure", minWidth: 50, visible: false },
    { id: "interest", label: "Interest (₦)", minWidth: 50, visible: false },
    {
      id: "repaymentDate",
      label: "Next Repayment Date",
      minWidth: 50,
      visible: true,
    },
    {
      id: "nextRepayment",
      label: "Repayment Amount (₦)",
      minWidth: 50,
      visible: true,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 50,
      visible:
        filterOption === retailFilters.ALL ||
        filterOption === retailFilters.VERIFIED
          ? true
          : false,
    },
    {
      id: "view",
      label: "View",
      minWidth: 50,
      visible: true,
      // filterOption === loansFilters.ALL ||
      // filterOption === loansFilters.PROSPECT
      //   ? false
      //   : true,
    },
  ];

  const handleView = (uid) => {
    dispatch(fetchRetailLoan(uid));

    const loan = loans.find((loan) => loan.uid === uid);

    let assigned = false;
    if (loan?.status === retailFilters.PENDING_RECOMMENDATION) {
      assigned = !!loan.recommender;
      if (!assigned) setOpenProceedToRecommend(true);
      else navigate(`/loans/overview/${loan.uid}`);
    } else if (loan?.status === retailFilters.PENDING_OFFER) {
      assigned = !!loan.offerer;
      if (!assigned && loan.recommender?.uid === user.uid)
        setOpenPreventOfferAttempt(true);
      else if (!assigned && loan.recommender?.uid !== user.uid)
        setOpenProceedToOffer(true);
      else navigate(`/loans/overview/${loan.uid}`);
    } else {
      navigate(`/loans/overview/${loan.uid}`);
    }
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                ?.filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {retailLoans.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : loans?.length > 0 ? (
              loans?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text.uid}>
                      {loan.uid}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.recommender
                        ? `${loan.recommender.firstName} ${loan.recommender.lastName}`
                        : "Unassigned"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.offerer
                        ? `${loan.offerer.firstName} ${loan.offerer.lastName}`
                        : "Unassigned"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.user.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {parseFloat(loan.loanAmount).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                    </Typography>
                  </TableCell>
                  {columns?.find((column) => column.id === "tenure")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography
                        sx={{
                          ...styles.table.text,
                          textTransform: "lowercase",
                        }}
                      >
                        {loan.loanTenure === 1
                          ? `${loan.loanTenure} Month`
                          : `${loan.loanTenure} Months`}
                      </Typography>
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "interest")
                    ?.visible ? (
                    <TableCell align="left">
                      <Typography sx={styles.table.text}>
                        {parseFloat(
                          loan.loanOfferTotalInterest ?? 0
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </TableCell>
                  ) : null}

                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {loan.nextRepaymentDate &&
                        format(
                          new Date(loan.nextRepaymentDate),
                          "dd/MM/yyyy hh:mm:ss aa"
                        )}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {parseFloat(
                        loan.loanOfferMonthlyRepayment ?? 0
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                    </Typography>
                  </TableCell>
                  {columns?.find((column) => column.id === "status")
                    ?.visible ? (
                    <TableCell align="left">
                      {getStatusDisplay(loan.status)}
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "view")?.visible ? (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={() => handleView(loan.uid)}
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns?.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!retailLoans.loading && loans?.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={retailLoans.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
      <ProceedToRecommend
        open={openProceedToRecommend}
        handleClose={() => setOpenProceedToRecommend(false)}
      />
      <ProceedToOffer
        open={openProceedToOffer}
        handleClose={() => setOpenProceedToOffer(false)}
      />

      {/* <ProceedToAction
        open={openProceedToAction}
        handleClose={() => setOpenProceedToAction(false)}
        role={role}
      /> */}
      <PreventOfferAttempt
        open={openPreventOfferAttempt}
        handleClose={() => setOpenPreventOfferAttempt(false)}
      />
      <DenyLoanView
        open={openDenyLoanView}
        handleClose={() => setOpenDenyLoanView(false)}
      />
    </Box>
  );
};
