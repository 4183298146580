import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { DeadState } from "../../../../../../common/components/DeadState";
import { useNavigate } from "react-router-dom";
import { PageNavigation } from "../../../../../../common/components/PageNavigation";
import { styles } from "../../../../../../common/css/styles";

const columns = [
  { id: "firstName", label: " First Name", minWidth: 10, visible: true },
  { id: "lastName", label: "Last Name", minWidth: 50, visible: true },
  { id: "email", label: "Email", minWidth: 50, visible: true },
  { id: "phoneNumber", label: "Phone number", minWidth: 50, visible: true },
  { id: "role", label: "Role", minWidth: 50, visible: false },
];

export const Guarantors = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                ?.filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data?.map((guarantor) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={guarantor.email}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {guarantor.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {guarantor.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {guarantor.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {guarantor.phoneNumber}
                    </Typography>
                  </TableCell>
                  {columns?.find((column) => column.id === "status")
                    ?.visible ? (
                    <TableCell align="left">
                      {/* {getStatusDisplay(loan.status)} */}
                    </TableCell>
                  ) : null}
                  {columns?.find((column) => column.id === "view")?.visible ? (
                    <TableCell align="left">
                      {/* <Button
                        variant="outlined"
                        // onClick={() => handleView(loan.uid)}
                        onClick={() => handleView()}
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button> */}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns?.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {!loans.loading && loanList?.length > 5 ? (
      <PageNavigation
        page={page}
        totalRecords={loans.totalRecords}
        handlePageChange={handlePageChange}
      />
    ) : (
      <></>
    )} */}
    </Box>
  );
};
