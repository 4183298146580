import { Box, Button, Typography } from "@mui/material";
// import { DateCalendar,  } from "@mui/x-date-pickers";
import React, { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { format } from "date-fns";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const CustomDate = ({
  handleCustomFilter,
  customFilter,
  close,
  applyCustomFilter,
}) => {
  const ref = useRef();
  //   window.addEventListener("click", (e) => {
  //     if (!ref.current?.contains(e.target)) close();
  //   });
  return (
    <Box
      ref={ref}
      sx={{
        position: "absolute",
        bgcolor: "#fff",
        display: "flex",
        zIndex: 999,
        top: "50px",
        flexDirection: "column",
      }}
      borderRadius={"12px"}
      boxShadow={1}
    >
      <Box p={2} sx={{ display: "flex", gap: "14px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={dayjs(customFilter.startDate)}
            onChange={(newValue) => handleCustomFilter("startDate", newValue)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={dayjs(customFilter.endDate)}
            onChange={(newValue) => {
              handleCustomFilter("endDate", newValue);
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        p={2}
        pb={0}
        sx={{
          display: "flex",
          gap: "50px",
          justifyContent: "end",
          height: "48px",
          alignItems: "center",
          borderTop: "1px solid #00000027",
        }}
      >
        <Button onClick={close} style={{ color: "inherit" }}>
          <Typography sx={{ fontSize: "14px", color: "#303332" }}>
            Cancel
          </Typography>
        </Button>
        <Button onClick={applyCustomFilter} color="inherit">
          <Typography sx={{ fontSize: "14px", color: "#303332" }}>
            Apply
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CustomDate;
