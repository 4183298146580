import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import { styles } from "../../../common/css/styles";
import { bridgeFinanceFilters } from "../../bridgeFinance/constants/bridgeFinanceFilters";
import { DeadState } from "../../../common/components/DeadState";
import { customerFilters } from "../../customers/constants/customerFilters";
import { format } from "date-fns";
import { retailFilters } from "../../loans/constants/retailFilters";
import { useLoanParams } from "../hooks/useLoanParams";
import { PageNavigation } from "../../../common/components/PageNavigation";
import { toTitleCase } from "../../../common/constants/utility";
import dayjs from "dayjs";

const getStatusDisplay = (status) => {
  switch (status) {
    case retailFilters.PENDING_RECOMMENDATION:
      return (
        <Typography sx={styles.bridgeTable.status.pendingVerification}>
          Pending Recommendation
        </Typography>
      );
    case retailFilters.PENDING_OFFER:
      return (
        <Typography sx={styles.bridgeTable.status.pendingOffer}>
          Pending Offer
        </Typography>
      );
    case retailFilters.OFFERED:
      return (
        <Typography sx={styles.bridgeTable.status.offered}>Offered</Typography>
      );
    case retailFilters.OFFER_REJECTED:
      return (
        <Typography sx={styles.bridgeTable.status.rejected}>
          Offer Rejected
        </Typography>
      );
    case retailFilters.DEFAULTING:
      return (
        <Typography sx={styles.bridgeTable.status.defaulting}>
          Defaulting
        </Typography>
      );
    case retailFilters.DECLINED:
      return (
        <Typography sx={styles.bridgeTable.status.declined}>
          Declined
        </Typography>
      );
    case retailFilters.ACTIVE:
      return (
        <Typography sx={styles.bridgeTable.status.active}>Active</Typography>
      );
    case retailFilters.COMPLETED:
      return (
        <Typography sx={styles.bridgeTable.status.completed}>
          Completed
        </Typography>
      );
    case retailFilters.NTUS:
      return <Typography sx={styles.bridgeTable.status.ntu}>NTU</Typography>;
    case retailFilters.PENDING_DISBURSEMENT:
      return (
        <Typography sx={styles.bridgeTable.status.pendingDisbursement}>
          Pending Disbursement
        </Typography>
      );
    default:
      break;
  }
};
const d = new Date();
export const LoansTable = ({ overview, handlePageChange, page, filter }) => {
  console.log(overview.loanInsights);
  const loans = overview.loanInsights?.loans;
  const customers = overview.pendingCustomers;
  const today = format(d.setDate(d.getDate()), "yyyy-MM-dd");
  console.log("filter", filter);
  const columns = [
    { id: "uid", label: "Loan UID", minWidth: 20, visible: true },
    {
      id: "status",
      label: "Status",
      minWidth: 20,
      visible: true,
    },
    { id: "type", label: "Loan Type", minWidth: 20, visible: true },
    {
      id: "amountDisbursed",
      label: "Amount Disbursed (₦)",
      minWidth: 20,
      visible: true,
    },
    {
      id: "amountPaid",
      label: "Amount Paid  (₦)",
      minWidth: 20,
      visible: true,
    },
    {
      id: "amountOutstanding",
      label: "Amount Outstanding  (₦)",
      minWidth: 20,
      visible: true,
    },
    {
      id: "customerName",
      label: "Customer Name",
      minWidth: 20,
      visible: true,
    },
    {
      id: "customerEmail",
      label: "Customer Email",
      minWidth: 20,
      visible: true,
    },
    {
      id: "customerPhone",
      label: "Customer Phone",
      minWidth: 20,
      visible: true,
    },
    {
      id: "interestRate",
      label: "Interest Rate",
      minWidth: 20,
      visible: true,
    },
    {
      id: "interestAmount",
      label: "Interest Amount",
      minWidth: 20,
      visible: true,
    },

    { id: "date", label: "Next Repayment Date", minWidth: 20, visible: true },
    {
      id: "nextRepaymentDate-today",
      label: "Days To Next Repayment Date",
      minWidth: 20,
      visible: true,
    },
    {
      id: "daysOutstanding",
      label: "Days Outstanding",
      minWidth: 20,
      visible: true,
    },
    {
      id: "recommender",
      label: "Recommender",
      minWidth: 20,
      visible: true,
    },
    {
      id: "offerer",
      label: "Offerer",
      minWidth: 20,
      visible: true,
    },
    // { id: "view", label: "View", minWidth: 20, visible: true },
  ];
  const getCols = (arr) => {
    if (filter === "") return arr;
    else return arr.filter((a) => a.id !== "status");
  };
  // console.log("format", format("2024-06-11T10:38:43.000+00:00", "yyyy-MM-dd"));
  // const { fetchLoanInsights, dispatch } = useLoanParams();
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount).replace("NGN", "₦");
  };
  const handleView = (id) => {};

  const calculateDate = (date, type) => {
    const val =
      type === "backward"
        ? dayjs(today).diff(date.slice(0, 10), "day", true)
        : dayjs(date.slice(0, 10)).diff(today, "day", true);
    return val < 1 ? 0 : val;
  };
  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  fontSize: 13,
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {getCols(columns)
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          {loans.length > 0 && !overview.loadingLoanInsights ? (
            loans.map((loan) => (
              <TableBody>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>{loan?.uid}</Typography>
                  </TableCell>
                  {filter === "" ? (
                    <TableCell align="left">
                      <Typography
                        sx={{ ...styles.table.text, whiteSpace: "nowrap" }}
                      >
                        {getStatusDisplay(loan?.status)}
                      </Typography>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                    >
                      {toTitleCase(loan?.type?.replaceAll("_", " "))}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={styles.table.text}>
                      {toPrice(loan?.amountDisbursed)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{ ...styles.table.text, textAlign: "center" }}
                    >
                      {toPrice(loan?.amountPaid)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{ ...styles.table.text, textAlign: "center" }}
                    >
                      {toPrice(loan?.amountOutstanding)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{ ...styles.table.text, textAlign: "center" }}
                    >
                      {toPrice(loan?.amountPaid)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{ ...styles.table.text, textAlign: "center" }}
                    >
                      {loan?.customerName}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{ ...styles.table.text, textAlign: "center" }}
                    >
                      {loan?.customerPhone}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      sx={{ ...styles.table.text, textAlign: "center" }}
                    >
                      {loan?.loanOfferInterestRate}%
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <TableCell align="left">
                      <Typography
                        sx={{ ...styles.table.text, textAlign: "center" }}
                      >
                        {loan?.loanOfferTotalInterest
                          ? toPrice(loan?.loanOfferTotalInterest)
                          : "N/A"}
                      </Typography>
                    </TableCell>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ ...styles.table.text }}>
                      {loan.nextRepaymentDate ? (
                        <span
                          style={{
                            ...styles.table.text,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {loan.nextRepaymentDate
                            ? format(
                                new Date(loan?.nextRepaymentDate),
                                "dd/MM/yyyy"
                              ).slice(0, 10)
                            : ""}
                          <span>
                            {loan.nextRepaymentDate
                              ? format(
                                  new Date(loan?.nextRepaymentDate),
                                  "hh:mm:ss aa"
                                )
                              : ""}
                          </span>
                        </span>
                      ) : (
                        "N/A"
                      )}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ ...styles.table.text }}>
                      {loan.nextRepaymentDate
                        ? calculateDate(loan.nextRepaymentDate, "forward")
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ ...styles.table.text }}>
                      {loan?.defaultingDate
                        ? calculateDate(loan?.defaultingDate, "backward")
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ ...styles.table.text }}>
                      {loan.recommender
                        ? loan.recommender.firstName +
                          " " +
                          loan.recommender.lastName
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ ...styles.table.text }}>
                      {loan.offerer
                        ? loan.offerer.firstName + " " + loan.offerer.lastName
                        : "N/A"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>
      {overview.loadingLoanInsights ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: "400px",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <></>
      )}
      {loans.length === 0 && !overview.loadingLoanInsights && (
        <Box sx={{}}>
          <DeadState />
        </Box>
      )}
      {!overview.loadingLoanInsights && loans?.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={overview.loanInsights.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
{
  /* {columns?.find((column) => column.id === "view")?.visible ? (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        onClick={() => handleView(loan.uid)}
                        sx={styles.table.text.viewButton}
                      >
                        View
                      </Button>
                    </TableCell>
                  ) : null} */
}
