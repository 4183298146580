import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Stack, FormControl, Select, MenuItem } from "@mui/material";
import { Add } from "@mui/icons-material";
import { styles } from "../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { filteredLoans, searchRetailLoans } from "./store/retailLoansSlice";
import { retailFilters } from "./constants/retailFilters";
import { LoansTable } from "./LoansTable";
import { useNavigate } from "react-router-dom";

export const Loans = () => {
  const retailLoans = useSelector((state) => state.retailLoans);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [activeFilter, setActiveFilter] = useState(retailFilters.ALL);

  const handleSearch = (searchTerm) => {
    dispatch(searchRetailLoans(1, searchTerm));
  };

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const filters = [
    { label: "All", value: retailFilters.ALL, visible: true },
    {
      label: "Pending Recommendation",
      value: retailFilters.PENDING_RECOMMENDATION,
      visible: true,
    },
    {
      label: "Pending Offer",
      value: retailFilters.PENDING_OFFER,
      visible: true,
    },
    {
      label: "Offered",
      value: retailFilters.OFFERED,
      visible: true,
    },
    {
      label: "Rejected Offers",
      value: retailFilters.OFFER_REJECTED,
      visible: true,
    },
    {
      label: "Declined Application",
      value: retailFilters.DECLINED,
      visible: true,
    },
    { label: "Active", value: retailFilters.ACTIVE, visible: true },
    { label: "Defaulting", value: retailFilters.DEFAULTING, visible: true },
    { label: "Completed", value: retailFilters.COMPLETED, visible: true },
    { label: "NTUs", value: retailFilters.NTUS, visible: true },
  ];

  useEffect(() => {
    dispatch(filteredLoans({ page: 1, filterOption: activeFilter }));
  }, [activeFilter]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(filteredLoans({ page: newPage, filterOption: activeFilter }));
  };

  const handleView = (uid) => navigate(`/loans/overview/${uid}`);
console.log("cbubcju")
  return (
    <>
      <SectionHeader headerText="Retail loans" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FormControl sx={{ minWidth: 120, my: 2 }}>
              <Select
                value={activeFilter}
                size="small"
                // onChange={handleLoanMetricsDateFilterChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ ...styles.dropdown, py: 2, height: 40, fontWeight: 900 }}
              >
                {filters
                  .filter((filter) => filter.visible === true)
                  .map((filter) => (
                    <MenuItem
                      key={filter.value}
                      sx={{ fontSize: 12, fontWeight: 700 }}
                      onClick={() => handleFilter(filter.value)}
                      variant="outlined"
                      value={filter.value}
                    >
                      {filter.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          {/* <Box sx={{ py: 2 }}>
            {filters
              .filter((filter) => filter.visible === true)
              .map((filter) => (
                <Button
                  key={filter.value}
                  onClick={() => handleFilter(filter.value)}
                  variant="outlined"
                  sx={
                    activeFilter === filter.value
                      ? styles.tableFilter.active
                      : styles.tableFilter.inactive
                  }
                >
                  {filter.label}
                </Button>
              ))}
          </Box> */}
          <LoansTable
            retailLoans={retailLoans}
            filterOption={activeFilter}
            page={page}
            handlePageChange={handlePageChange}
            handleView={handleView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};
