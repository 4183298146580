import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DeadState } from "../../../../../common/components/DeadState";
import { styles } from "../../../../../common/css/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PageNavigation } from "../../../../../common/components/PageNavigation";
import { format } from "date-fns";
import { fetchBusinessLoanHistory } from "../../store/businessLoanSlice";
import { businessLoanFilters } from "../../constants/businessLoanFilters";

const columns = [
  {
    id: "loanAmount",
    label: "Loan amount (₦)",
    minWidth: 120,
    align: "left",
  },
  {
    id: "tenure",
    label: "Tenure",
    minWidth: 50,
    align: "left",
  },
  {
    id: "date",
    label: "Date",
    minWidth: 50,
    align: "left",
  },
  {
    id: "totalInterest",
    label: "Total Interest",
    minWidth: 100,
    align: "left",
  },
  {
    id: "totalRepayment",
    label: "Total Repayment",
    minWidth: 120,
    align: "left",
  },
  {
    id: "loanStatus",
    label: "Loan Status",
    minWidth: 50,
    align: "left",
  },
  {
    id: "view",
    label: "View",
    minWidth: 50,
    align: "left",
  },
];

export const LoanHistory = () => {
  const businessLoan = useSelector((state) => state.businessLoan);
  const loanHistory = businessLoan.history?.loans;
  const { loanid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  //  const openAddDialog = () => {
  //    setIsAddDialogOpen(true);
  //  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleAddButtonClick = () => {
    setIsAddDialogOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(fetchBusinessLoanHistory({ loanUid: loanid, page: newPage }));
  };

  const handleView = (loanid) =>
    navigate(`/sme/asset-financing/details/${loanid}`);

  const getStatusDisplay = (status) => {
    switch (status) {
      case businessLoanFilters.PENDING_RECOMMENDATION:
        return (
          <Typography sx={styles.bridgeTable.status.pendingVerification}>
            Pending Recommendation
          </Typography>
        );
      case businessLoanFilters.PENDING_OFFER:
        return (
          <Typography sx={styles.bridgeTable.status.pendingOffer}>
            Pending Offer
          </Typography>
        );
      case businessLoanFilters.OFFERED:
        return (
          <Typography sx={styles.bridgeTable.status.offered}>
            Offered
          </Typography>
        );
      case businessLoanFilters.OFFER_REJECTED:
        return (
          <Typography sx={styles.bridgeTable.status.rejected}>
            Offer Rejected
          </Typography>
        );
      case businessLoanFilters.DEFAULTING:
        return (
          <Typography sx={styles.bridgeTable.status.defaulting}>
            Defaulting
          </Typography>
        );
      case businessLoanFilters.PENDING_APPROVAL:
        return (
          <Typography sx={styles.bridgeTable.status.pendingLien}>
            Pending Approval
          </Typography>
        );
      case businessLoanFilters.ACTIVE:
        return (
          <Typography sx={styles.bridgeTable.status.active}>Active</Typography>
        );
      case businessLoanFilters.COMPLETED:
        return (
          <Typography sx={styles.bridgeTable.status.completed}>
            Completed
          </Typography>
        );
      case businessLoanFilters.NTU:
        return <Typography sx={styles.bridgeTable.status.ntu}>NTU</Typography>;
      case businessLoanFilters.PENDING_DISBURSEMENT:
        return (
          <Typography sx={styles.bridgeTable.status.pendingDisbursement}>
            Pending Disbursement
          </Typography>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    // dispatch(fetchBusinessLoanHistory({ loanUid: loanid, page: 1 }));
  }, []);

  return (
    <>
      <Box
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 2,
            }}
          >
            {/* <Button
                onClick={handleAddButtonClick}
                //   variant="outlined"
                sx={{
                  height: 29,
                  border: 1,
                  color: "#494D4A",
                  borderColor: "#61666333",
                  mt: 2.5,
                  textTransform: "capitalize",
                  fontSize: 12,
                }}
              >
                <Add sx={{ fontSize: 12, pr: 1 }} /> Add
              </Button> */}
          </Box>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "none",
              boxShadow: "none",
            }}
          >
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ backgroundColor: "transparent" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-head": {
                        color: "#000",
                        backgroundColor: "transparent",
                        border: "4px solid black",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          borderTop: "1px solid #B5B5B5",
                          borderLeft: "none",
                          borderRight: "none",
                          borderBottom: "1px solid #B5B5B5",
                          fontSize: 13,
                          fontWeight: 600,
                          height: 20,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businessLoan.loadingHistory ? (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
                    >
                      <CircularProgress />
                    </TableCell>
                  ) : loanHistory?.length > 0 ? (
                    loanHistory?.map((loan) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={loan.uid}
                        sx={{
                          "& .MuiTableCell-body": {
                            color: "#62635E",
                            backgroundColor: "#fff",
                            border: "none",
                            fontSize: 13,
                            fontWeight: 400,
                          },
                        }}
                      >
                        <TableCell>
                          <Typography sx={styles.table.text}>
                            {parseFloat(
                              loan.loanOfferAmount ?? 0
                            )?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            noWrap
                            sx={{
                              ...styles.table.text,
                              textTransform: "lowercase",
                            }}
                          >
                            {isNaN(loan.loanTenure)
                              ? "0"
                              : loan.loanTenure === 1
                              ? `${loan.loanTenure} month`
                              : `${loan.loanTenure} months`}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={styles.table.text}>
                            {loan.createdAt &&
                              format(
                                new Date(loan.createdAt),
                                "dd/MM/yyyy hh:mm:ss aa"
                              )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={styles.table.text}>
                            {parseFloat(
                              loan.loanOfferTotalInterest ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={styles.table.text}>
                            {parseFloat(
                              loan.loanOfferTotalRepayment ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {getStatusDisplay(loan.status)}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="outlined"
                            onClick={() => handleView(loan.uid)}
                            sx={styles.table.text.viewButton}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center" }}
                    >
                      <DeadState />
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {!businessLoan.loadingHistory && loanHistory?.length > 0 ? (
              <PageNavigation
                page={page}
                totalRecords={businessLoan.history?.totalRecords}
                handlePageChange={handlePageChange}
              />
            ) : (
              <></>
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
};
