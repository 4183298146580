import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import doneIcon from "../../../../common/images/done-icon.svg";
import { verifyCustomer } from "../store/loanSlice";

const options = ["Reason1", "Reason2", "Reason3", "Reason4", "Reason5"];

const defaultValues = {
  kycRejectionReasons: [],
  otherReasons: "",
};

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 2;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const schema = yup.object().shape({
  kycRejectionReasons: yup.array().min(1, "Please select at least one reason"),
  otherReasons: yup.string().when("kycRejectionReasons", {
    is: (val) => val.length === 0,
    then: () => yup.string().required("Other reason is required"),
    otherwise: () => yup.string().notRequired(),
  }),
});

export const Offer = ({ open, handleClose, customer }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const [reasons, setReasons] = useState([]);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);
    navigate("/customers/accounts");
  };

  const onSubmit = (data) => {
    dispatch(
      verifyCustomer({
        isKycAccepted: false,
        userUid: customer.uid,
        kycRejectionReasons: [
          ...data.kycRejectionReasons,
          data.otherReasons,
        ].filter((n) => n),
      })
    );
    handleClose();
    setOpenSecondDialog(true);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setReasons(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  useEffect(() => {
    let timeoutId;
    if (openSecondDialog) {
      timeoutId = setTimeout(() => {
        handleCloseSecondDialog();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openSecondDialog]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
      >
        <Grid
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          pb={1}
          sx={{ width: 448 }}
        >
          <DialogTitle sx={{ color: "#000" }}>Recomended For Offer</DialogTitle>

          <DialogContent>
            <Typography sx={{ fontSize: 12, fontWeight: 400, pb: 1, mb: 2 }}>
              Please provide your reasons for recommending this offer
            </Typography>
            <Grid container spacing={3}>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Loan Offer
                </Typography>
                <Controller
                  name="loanOffer"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={!!errors.loanOffer}
                      helperText={errors.loanOffer?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Loan Tenure
                </Typography>
                <Controller
                  name="loanTenure"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={!!errors.loanTenure}
                      helperText={errors.loanTenure?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Resaon for loan offer
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={reasons}
                    onChange={handleChange}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          fontSize: 5,
                        }}
                      >
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {options.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={reasons.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Other reasons for loan offer
                </Typography>
                <Controller
                  name="otherReasons"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={!!errors.otherReasons}
                      helperText={errors.otherReasons?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item sx={{ width: "100%", fontSize: 10 }}>
                <FormControlLabel
                  control={<Checkbox sx={{ fontSize: 10 }} defaultChecked />}
                  label="Use Custom Rate"
                />
                <Typography sx={{ fontSize: 14, fontWeight: 400, pb: 1 }}>
                  Interest Rate
                </Typography>
                <Controller
                  name="interestRate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      error={!!errors.interestRate}
                      helperText={errors.interestRate?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                reset();
              }}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                color: "#616663",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                boxShadow: "none",
                color: "#fff",
                textTransform: "capitalize",
                px: 3,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 2,
                mr: 2,
              }}
            >
              Recomended for offer
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* Second Dialog */}
      <Dialog open={openSecondDialog} onClose={handleCloseSecondDialog}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogContent>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <img src={doneIcon} alt="done Icon" />
            </Box>
            <DialogContentText sx={{ textAlign: "center" }}>
              Recomendation was successful.
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};
