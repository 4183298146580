export const loansFilters = {
  ALL: "",
  APPLICATION_STATUS: "APPLICATION_STATUS",
  PENDING_RECOMMENDATION: "PENDING RECOMMENDATION",
  PENDING_OFFER: 'PENDING OFFER',
  OFFERED: "OFFERED",
  ACTIVE: "ACTIVE",
  REJECTED_OFFERS: "REJECTED_OFFERS",
  DEFAULTING: "DEFAULTING",
  DECLINED: "DECLINED",
  COMPLETED: "COMPLETED",
  NTUS: "NTUS",
};

export const loansDetailsFilters = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  EMPLOYMENT: "EMPLOYMENT",
  BANK_INFO: "BANK_INFO",
  NEXT_OF_KIN: "NEXT_OF_KIN",
  IDENTIFICATION: "IDENTIFICATION",
};
