import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { feedback } from "../../../../common/constants/feedback";
import { handleError } from "../../../../common/constants/utility";
import {
  approveForDisbursement,
  disburse,
  getBusinessTeamMembersByLoanId,
  getCollateral,
  getDocuments,
  getGuarantors,
  getLoan,
  getLoanHistory,
  offerLoan,
  recommendLoanOffer,
  setLoanOfferer,
  setLoanRecommender,
  uploadProofOfRepayment,
} from "../../../../common/api/sme";
import { businessLoanFilters } from "../constants/businessLoanFilters";

const initialState = {
  loan: null,
  loading: false,
  loadingCollateral: false,
  loadingGuarantor: false,
  loadingDocuments: false,
  loadingHistory: false,
  history: null,
  disbursed: "",
  approved: "",
  feedback: "",
  error: [],
};

export const loanOptions = {
  RECOMMEND: "RECOMMEND",
  MAKE_OFFER: "MAKE_OFFER",
  VIEW: "VIEW",
};

export const fetchBusinessLoan = createAsyncThunk(
  "sme/fetchLoan",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoan(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchBusinessDocumentsFromLoan = createAsyncThunk(
  "sme/loan/fetchDocuments",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getDocuments(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);
export const fetchBusinessTeamMembers = createAsyncThunk(
  "sme/fetchTeamMembers",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getBusinessTeamMembersByLoanId(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const setRecommender = createAsyncThunk(
  "sme/loan/setRecommender",
  async (loanUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await setLoanRecommender(loanUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const recommendOrDeclineOffer = createAsyncThunk(
  "sme/loan/recommendForOffer",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await recommendLoanOffer(model);
      dispatch(fetchBusinessLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const setOfferer = createAsyncThunk(
  "sme/loan/setOfferer",
  async (loanUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await setLoanOfferer(loanUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const makeOrDeclineOffer = createAsyncThunk(
  "sme/loan/offerLoan",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await offerLoan(model);
      dispatch(
        updateLoanDetails({
          status: model.isLoanOffered
            ? businessLoanFilters.OFFERED
            : businessLoanFilters.DECLINED,
        })
      );
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchBusinessLoanHistory = createAsyncThunk(
  "sme/fetchLoanHistory",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoanHistory(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchCollateralInfo = createAsyncThunk(
  "sme/loan/fetch-collateral",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCollateral(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchGuarantorInfo = createAsyncThunk(
  "sme/loan/fetch-guarantor",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getGuarantors(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const approveLoan = createAsyncThunk(
  "sme/loan/approve",
  async (loanUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await approveForDisbursement(loanUid);
      dispatch(fetchBusinessLoan(loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const disburseLoan = createAsyncThunk(
  "sme/loan/disburse",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await disburse(model);
      dispatch(fetchBusinessLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const closeLoan = createAsyncThunk(
  "sme/loan/close",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadProofOfRepayment(model);
      dispatch(fetchBusinessLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const businessLoanSlice = createSlice({
  name: "sme/loan",
  initialState,
  reducers: {
    updateLoanDetails: (state, action) => {
      state.loan = { ...state.loan, ...action.payload };
      state.error = [];
    },
    clearLoanDetails: (state) => {
      state.loan = null;
      state.error = [];
    },
    clearLoanFeedback: (state) => {
      state.feedback = "";
      state.lienUpload = "";
      state.disbursed = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessLoan.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBusinessLoan.fulfilled, (state, action) => {
      state.loan = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchBusinessLoan.rejected, (state, action) => {
      state.loan = null;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchBusinessTeamMembers.pending, (state, action) => {
      state.loadingTeamMembers = true;
    });
    builder.addCase(fetchBusinessTeamMembers.fulfilled, (state, action) => {
      state.teamMembers = action.payload.data;
      state.loadingTeamMembers = false;
      state.error = [];
    });
    builder.addCase(fetchBusinessTeamMembers.rejected, (state, action) => {
      state.teamMembers = null;
      state.loadingTeamMembers = false;
      state.error = action.payload;
    });
    builder.addCase(setRecommender.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(setRecommender.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(recommendOrDeclineOffer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(recommendOrDeclineOffer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(setOfferer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(setOfferer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(makeOrDeclineOffer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(makeOrDeclineOffer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchBusinessLoanHistory.pending, (state, action) => {
      state.loadingHistory = true;
      state.error = [];
    });
    builder.addCase(fetchBusinessLoanHistory.fulfilled, (state, action) => {
      state.history = action.payload.data;
      state.loadingHistory = false;
      state.error = [];
    });
    builder.addCase(fetchBusinessLoanHistory.rejected, (state, action) => {
      state.loadingHistory = false;
      state.error = action.payload;
    });
    builder.addCase(fetchCollateralInfo.pending, (state, action) => {
      state.loadingCollateral = true;
      state.error = [];
    });
    builder.addCase(fetchCollateralInfo.fulfilled, (state, action) => {
      state.loadingCollateral = false;
      if (state.loan) state.loan.collaterals = action.payload.data;
      state.error = [];
    });
    builder.addCase(fetchCollateralInfo.rejected, (state, action) => {
      state.loadingCollateral = false;
      state.error = action.payload;
    });
    builder.addCase(fetchGuarantorInfo.pending, (state, action) => {
      state.loadingGuarantor = true;
      state.error = [];
    });
    builder.addCase(fetchGuarantorInfo.fulfilled, (state, action) => {
      state.loadingGuarantor = false;
      if (state.loan) state.loan.guarantors = action.payload.data;
      state.error = [];
    });
    builder.addCase(fetchGuarantorInfo.rejected, (state, action) => {
      state.loadingGuarantor = false;
      state.error = action.payload;
    });
    builder.addCase(fetchBusinessDocumentsFromLoan.pending, (state, action) => {
      state.loadingDocuments = true;
      state.error = [];
    });
    builder.addCase(
      fetchBusinessDocumentsFromLoan.fulfilled,
      (state, action) => {
        state.loadingDocuments = false;
        if (state.loan) state.loan.documents = action.payload.data;
        state.error = [];
      }
    );
    builder.addCase(
      fetchBusinessDocumentsFromLoan.rejected,
      (state, action) => {
        state.loadingDocuments = false;
        state.error = action.payload;
      }
    );

    builder.addCase(disburseLoan.pending, (state, action) => {
      state.disbursed = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(disburseLoan.fulfilled, (state, action) => {
      state.disbursed = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(disburseLoan.rejected, (state, action) => {
      state.disbursed = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(approveLoan.pending, (state, action) => {
      state.approved = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(approveLoan.fulfilled, (state, action) => {
      state.approved = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(approveLoan.rejected, (state, action) => {
      state.approved = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(closeLoan.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(closeLoan.fulfilled, (state, action) => {
      state.feedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(closeLoan.rejected, (state, action) => {
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
  },
});

export const { updateLoanDetails, clearLoanDetails, clearLoanFeedback } =
  businessLoanSlice.actions;
export default businessLoanSlice.reducer;
