import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { styles } from "../../common/css/styles";
import { getKycInfo, resetKyc } from "../kyc/kycSlice";
import { stage } from "../kyc/stages";
import { BankStatement } from "../kyc/stages/BankStatement";
import { CreditBureau } from "../kyc/stages/CreditBureau";
import { LoanHistory } from "./details/loanHistory/LoanHistory";
import { CardSide } from "./components/CardSide";
import { Personal } from "./details/personal/Personal";
import { clearCustomer, fetchCustomer } from "../customers/store/customerSlice";
import {
  clearLoanDetails,
  fetchRetailLoan,
  fetchRetailLoanHistory,
} from "./store/retailLoanSlice";
import { RecommendationInfo } from "./details/RecommendationInfo";

export const LoanOverview = () => {
  const [currentStage, setCurrentStage] = useState(stage.PERSONAL);
  const retailLoan = useSelector((state) => state.retailLoan);
  const customer = useSelector((state) => state.customer);
  // const customer = useSelector(({ customer }) => customer.customer);
  const loan = retailLoan.loan;
  const { loanid } = useParams();
  const kycData = loan && JSON.parse(loan.customerKycObject);
  const customerBankData = loan && JSON.parse(loan.customerBankObject);
  const bvnData = loan && JSON.parse(loan.customerBvnObject);
  const ninData = loan && JSON.parse(loan.customerNationalIdObject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getKYCStage = () => {
    switch (currentStage) {
      case stage.PERSONAL:
        return <Personal customer={customer.customer} data={kycData} />;
      case stage.LOAN:
        return <LoanHistory customer={customer.customer} data={kycData} />;
      case stage.CREDIT_BUREAU:
        return <CreditBureau customer={customer.customer} data={kycData} />;
      case stage.BANK:
        return <BankStatement customer={customer.customer} data={kycData} />;
      case stage.RECOMMENDATION:
        return <RecommendationInfo loan={loan} />;
      default:
        return <Personal customer={customer.customer} />;
    }
  };

  const handleBackToTable = () => navigate(-1);
  const handleTabChange = (stage) => setCurrentStage(stage);

  useEffect(() => {
    if (loan === null) dispatch(fetchRetailLoan(loanid));
    return () => {
      dispatch(clearLoanDetails());
      dispatch(clearCustomer());
      dispatch(resetKyc());
    };
  }, []);

  useEffect(() => {
    if (!!loan) {
      dispatch(fetchCustomer(loan?.user.uid));
      dispatch(getKycInfo(loan?.user.uid));
      // dispatch(
      //   fetchRetailLoanHistory({ userUid: customer.customer?.uid, page: 1 })
      // );
    }
  }, [retailLoan.loading]);

  useEffect(() => {
    if (!customer.loading)
      dispatch(
        fetchRetailLoanHistory({ userUid: customer.customer?.uid, page: 1 })
      );
  }, [customer.loading]);

  return (
    <>
      <SectionHeader
        headerText={
          <Typography sx={{ fontWeight: 700, fontSize: 32 }}>
            <Tooltip
              title="Back to retail loans table"
              arrow
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                aria-label="back"
                onClick={handleBackToTable}
                sx={{ mb: 0.8 }}
              >
                <ArrowBack sx={{ fontSize: 30, color: "#000" }} />
              </IconButton>
            </Tooltip>
            Retail Loans
          </Typography>
        }
      />

      {retailLoan.loading || !!!customer.customer?.wallet ? (
        <WhiteBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
            }}
          >
            <CircularProgress sx={{ alignItems: "center" }} />
          </Box>
        </WhiteBox>
      ) : (
        <Grid
          container
          sx={{
            display: {
              // xs: "none",
              md: "block",
              minHeight: "100vh",
              // backgroundColor: "yellow"
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={false}
              md={4}
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                  // position: "relative",
                  // height: "100vh",
                  // backgroundImage: "url('../images/logo-sm.png')",
                  // backgroundColor: "yellow"
                },
              }}
            >
              {/* Top */}
              <WhiteBox>
                <CardSide
                  customer={customer.customer}
                  loan={loan}
                  kycData={kycData}
                />
              </WhiteBox>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={8}
              sx={{ p: 2, minHeight: "100vh" }}
            >
              <WhiteBox>
                <Box sx={{ p: 2 }}>
                  <Box sx={{ borderBottom: 1, borderBottomColor: "#F0F1ED" }}>
                    <Button
                      onClick={() => handleTabChange(stage.PERSONAL)}
                      sx={
                        currentStage === stage.PERSONAL
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Personal Details
                    </Button>
                    <Button
                      onClick={() => handleTabChange(stage.LOAN)}
                      sx={
                        currentStage === stage.LOAN
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Loan History
                    </Button>
                    {/* <Button
                      onClick={() => handleTabChange(stage.CREDIT_BUREAU)}
                      sx={
                        currentStage === stage.CREDIT_BUREAU
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Credit Bureau
                    </Button>
                    <Button
                      onClick={() => handleTabChange(stage.BANK)}
                      sx={
                        currentStage === stage.BANK
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Bank Statement
                    </Button> */}
                    <Button
                      onClick={() => handleTabChange(stage.RECOMMENDATION)}
                      sx={
                        currentStage === stage.RECOMMENDATION
                          ? styles.customerAccountVerification.sectionButton
                              .selected
                          : styles.customerAccountVerification.sectionButton
                              .notSelected
                      }
                    >
                      Recommendation
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "",
                      justifyContent: "space-between",
                      width: "100%",
                      //   alignItems: "normal",
                    }}
                  >
                    {getKYCStage()}
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
